angular.module('angus.controllers').controller( 'subRegCtrl', [
	'$scope', '$rootScope', '$http', 'actionViews', 'constantsService', '_', 'Paging',
	function( $scope, $rootScope, $http, actionViews, constantsService, _, Paging ) {
		'use strict';

		$scope.searchTerm = '';	  
		$scope.paging = new Paging(200); 
		$scope.sortBy = 'name';
		$scope.ascending = true;  

		$scope.clearSearch = function() {
			$scope.searchTerm = '';
			return $scope.getSubscribers();
		};

		$scope.subscriberSelected = function( subscriberId, itemName, registrationStatusKey, isPaygo, abosKey ) {
			$rootScope.impersonateAbosKey = abosKey;
			$scope.widget.openDetailView( actionViews.getActionView( $scope.widget.code ), {
				subscriberId: subscriberId,
				itemName: itemName,
				registrationStatusKey: registrationStatusKey,
				subscriberIsPaygo : isPaygo
			} );
		};


		$scope.getSubscribers= function(pageNumber){
			var params = {
				searchTerm : $scope.searchTerm, 
				registrationStatusKeys:  $scope.widget.instance.settings.registrationStatusKeys,
				isAdept: $scope.widget.instance.settings.isAdept || false,
				isBrite: $scope.widget.instance.settings.isBrite || false,
				isAms: $scope.widget.instance.settings.isAms || false,
				backOfficeSystemIds: $scope.widget.instance.settings.backOfficeSystemIds,
				sortBy: $scope.sortBy,
				sortAscending: $scope.ascending
			}

			var url = 'api/subscribers';

            return $scope.paging.getPage(url, pageNumber, params)
				.then(function (subscribers) {
					if (subscribers.items != $scope.subscribers) {

						var subscribers = subscribers.items;

						_.forEach(subscribers, function(subscriber){
							var currentDate = new Date();
							var createdDate = new Date(subscriber.createdOn);

							var elapsed = currentDate - createdDate;

							var loyaltyMonths = Math.floor(elapsed / 2629743000);
							var loyaltyYears = Math.floor(loyaltyMonths / 12);

							subscriber.loyaltyYears = loyaltyYears.toString() + 'y ' + (loyaltyMonths - (loyaltyYears * 12)).toString().padStart(2, '0') + 'm';
							
							if(subscriber.registrationStatusKey == constantsService.registrationStatus.live) {
								subscriber.displayStatus = 'Live';
							}
							else if(subscriber.registrationStatusKey == constantsService.registrationStatus.connectionSetup) {
								subscriber.displayStatus = 'Connection Setup';
							}
							else {
								subscriber.displayStatus = 'Deleted';
							}
						});

						$scope.subscribers = subscribers;
					}

					/* Inside the promise reset the column visibility. That way the columns don't flash on or off when the settings change. */
					setColumnVisibility()

					$scope.widget.contentLoaded = true;
				});
		};

		$scope.sort=function(sortBy, direction) {
			if ($scope.sortBy == sortBy) {
				 $scope.ascending = !$scope.ascending;
			} else {
				$scope.sortBy = sortBy;

				if (direction === 'descending')
				{
					$scope.ascending = false;
				}
				else {
					$scope.ascending = true;
				}
				
			}
			$scope.getSubscribers(1);
		}

		function setColumnVisibility(){
			const registrationStatusKeys = $scope.widget.instance.settings.registrationStatusKeys;

			/* Determine if we should show the Live column. Show it if at least 2 registration statues are selected and one of them is the Live status */
			$scope.isLiveVisible = false;
			
			if(!registrationStatusKeys || registrationStatusKeys.length == 0){
				/* No statuses were selected */
				$scope.isLiveVisible = true;
			}
			else if (registrationStatusKeys.length > 1 && registrationStatusKeys.includes(constantsService.registrationStatus.live.toString())) {
				/* More than one status was selected and one of those statues is Live */
				$scope.isLiveVisible = true;
			}


			/* Only show the registration status column if Deleted is selected */	
			$scope.isStatusVisible = false;
			
			if(!registrationStatusKeys || registrationStatusKeys.length == 0){
				/* No statuses were selected */
				$scope.isStatusVisible = true;
			}
			else if (registrationStatusKeys.includes(constantsService.registrationStatus.deleted.toString())){
				/* No statuses selected, so all will be included. Show the Live column */
				$scope.isStatusVisible = true;
			}
		}

		
		function init() {		
			return $scope.widget.promises.monitor($scope.getSubscribers());			
		}

        $scope.widget.setDefaultInitialization(init);

		$scope.search = function() {
			$scope.getSubscribers();
		};

		$scope.$on('widgetSettingsChanged', function() {
           init();
        });
	} 
] );