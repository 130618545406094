angular.module('angus.controllers')
    .controller('signInCtrl', [
        '$scope', '$rootScope', '$location', '$http', '$timeout', '$window', 'authenticationService', 'moment', 'brandingService', '$q', 'modalService', 'fluentRest', '$mdToast', '$analytics',
        function($scope, $rootScope, $location, $http, $timeout, $window, authenticationService, moment, brandingService, $q, modalService, fluentRest, $mdToast) {

            'use strict';

            $scope.$broadcast('show-errors-check-validity');
            $scope.alerts = [];

            $scope.branding = brandingService;

            $scope.showTerms = false;

            $scope.toggleTerms = function(){
                $scope.showTerms = !$scope.showTerms;
            }



            $scope.impersonate = function(user) {

                var currentWindow = 'currentWindow';
                var newWindow = 'newWindow';


                fluentRest
                    .api()
                    .subscribers(user.subscriber.id)
                    .users(user.userId)
                    .impersonate()
                    .get()
                    .then(function(user) {
                        var scope = {
                            currentWindow: currentWindow,
                            newWindow: newWindow,
                            username: user.username
                        };

                        return modalService
                            .openTopBarModal('templates/global/html/impersonationSelection.html', scope)
                            .then(function(selection) {
                                if (!selection) return;
                                else if (selection == newWindow) {
                                    $window.open(('/#/Impersonate?subscriberId={0}&userId={1}').format(user.subscriberId, user.userId), '_blank');
                                    return;
                                } else if (selection == currentWindow) {
                                    authenticationService.impersonateUser($rootScope.user.username, user);
                                    $window.location.reload(true);
                                }
                            });
                    })
                    .catch(function(err) {
                        console.log('fail!: ', err);
                    });
            };

            $scope.signIn = function() {
                var deferred = $q.defer();
                $scope.alerts = [];

                $http.post('/signIn', $scope.model)
                    .success(function(data) {
                        $rootScope.user = authenticationService.setUserInfo(data.user, data.token, data.expires);

                        $location.path('/');

                        return deferred.resolve();
                    }).error(function(data) {
                        delete $scope.model.password;
                        authenticationService.clearUserInfo();

                        $mdToast.show($mdToast.simple().textContent((data && data.msg || 'Invalid username or password.')));

                        return deferred.reject();
                    });

                    return deferred.promise;
            };

            $scope.closeAlert = function(index) {
                $scope.alerts.splice(index, 1);
            };
        }
    ]);
