angular.module('angus.services').service('adeptService', [
    '$http',
    function($http) {

        function getAdeptGroups(subscriberId) {
            return queryAdeptGroups(subscriberId)
                .then(function(res){
                    return res.data;
                });
        }

        function getAdeptGroup(subscriberId, adeptGroupsId) {
            return queryAdeptGroup(subscriberId, adeptGroupsId)
                .then(function(res){
                    return res.data;
                });
        }

        function getExecutions(subscriberId, adeptGroupIds, startDate, endDate, includeUnscheduledDays) {
            return $http({
                url: 'api/subscribers/{0}/adept/executions'.format(subscriberId),
                method: 'POST',
                data: {
                    dynamicReserveSettingsKeys: adeptGroupIds,
                    startDate: startDate,
                    endDate: endDate,
                    includeUnscheduledDays: includeUnscheduledDays
                }
            })
                .then(function (res) {
                    return res.data;
                })
        }

        function getExecutionDetails(subscriberId, dynamicReserveExecutionKey, containerKey, startDate, endDate) {
            return $http({
                url: 'api/subscribers/{0}/adept/executions/executionDetails'.format(subscriberId),
                method: 'POST',
                data: {
                    dynamicReserveExecutionKey: dynamicReserveExecutionKey,
                    containerKey: containerKey,
                    startDate: startDate,
                    endDate: endDate
                }
            })
                .then(function (res) {
                    return res.data;
                });
        }

        function getTankExclusions(subscriberId, containerKey) {
            return $http({
                url: 'api/subscribers/{0}/adept/executions/tankExclusions'.format(subscriberId),
                method: 'POST',
                data: {
                    containerKey: containerKey
                }
            })
                .then(function(res) {
                    return res.data;
                });
        }

        function queryAdeptGroups(subscriberId) {
            return $http.get(('api/subscribers/{0}/adept/groups/').format(subscriberId));
        }

        function queryAdeptGroup(subscriberId, adeptGroupId) {
            return $http.get(('api/subscribers/{0}/adept/groups/{1}').format(subscriberId, adeptGroupId));
        }

        return {
            getAdeptGroups: getAdeptGroups,
            getAdeptGroup: getAdeptGroup,
            getExecutions: getExecutions,
            getExecutionDetails: getExecutionDetails,
            getTankExclusions: getTankExclusions
        };
    }
])