angular.module('angus.services')
    .factory("ProfileResource", [
        '$resource', function($resource) {
            return $resource("api/users/:userId",
                { userId: '@userId' },
                {
                    updateEmail: { method: "PATCH" },
                    updatePassword: { method: "PUT" }
                }
            );
        }
    ]);