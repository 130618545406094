angular.module('angus.services').service('userService', [
    '$http', '$rootScope', '$window', 'authenticationService', 'modalService',
    function($http, $rootScope, $window, authenticationService, modalService) {

        this.impersonateUser = function(subscriberId, userId) {
            const modalOptions = {
                newWindow: 'newWindow',
                currentWindow: 'currentWindow'
            };

            $http.get(('api/subscribers/{0}/users/{1}/impersonate').format(subscriberId, userId))
                .then(function(res){
                    const user = res.data;

                    var scope = {
                        currentWindow: modalOptions.currentWindow,
                        newWindow: modalOptions.newWindow,
                        userName: user.userName
                    };

                    return modalService.openTopBarModal('templates/global/html/impersonationSelection.html', scope)
                        .then(function (selection){
                           if (selection === modalOptions.newWindow) {
                                $window.open(('/#/Impersonate?subscriberId={0}&userId={1}').format(subscriberId, user.userId), '_blank');
                           }
                           else if (selection === modalOptions.currentWindow) {
                                authenticationService.impersonateUser($rootScope.user.username, user);
                                $window.location.reload(true);
                           }
                        });
                });
        };


           
        this.impersonate = function(user) {

            const currentWindow = 'currentWindow';
            const newWindow = 'newWindow';
            
            fluentRest.api().subscribers($scope.subscriberId).users(user.userId).impersonate().get()
                .then(function(user) {
                    var scope = {
                        currentWindow: currentWindow,
                        newWindow: newWindow,
                        username: user.username
                    };

                    return modalService
                        .openTopBarModal('templates/global/html/impersonationSelection.html', scope)
                        .then(function(selection) {
                            if (!selection) return;
                            else if (selection == newWindow) {

                                $window.open(('/#/Impersonate?subscriberId={0}&userId={1}').format($scope.subscriberId, user.userId), '_blank');
                                
                                return;
                            } else if (selection == currentWindow) {
                                authenticationService.impersonateUser($rootScope.user.username, user);
                                $window.location.reload(true);
                            }
                        });
                })
                .catch(function(err) {
                    console.log('fail!: ', err);
                });
        };
    }
]);