angular.module('angus.services').service('drillService', ['$rootScope', '$http', 'actionViews', 'modalService', 'actionViewTemplates', 'constantsService', '$q', 'subscriberService',
	function($rootScope, $http, actionViews, modalService, actionViewTemplates, constantsService, $q, subscriberService) {

		'use strict';

		var _context =  {
			delivery    : 'delivery',
			service     : 'service',
			margin 	    : 'margin',
			liquidFuels : 'liquidFuels'
		};

		function openLiquidFuelsHistoryReport(value) {
			var scope = {
				widgetName: 'Liquid Fuels History',
				widgetIcon: 'history',
				widget: {
					instance: {
						id: 'liquidFuelsHistory'
					}
				},
				model: {
					accountNumber    : value.accountNumber,
					tankNumber       : value.tankNumber,
					containerKey     : value.containerKey,
					productName      : value.productName,
					customerKey      : value.customerKey,
					deliveryLocation : value.deliveryLocation,
					tradeClass       : value.tradeClass,
					customerName     : value.customerName,
					hierarchyNode    : value.hierarchyNode
				}
			};

			modalService
				.openActionView(actionViewTemplates.liquidFuelsHistoryDefault, scope, 'liquidFuelsHistory');
		}

		


		function openMarginHistoryReport(value) {
			var scope = {
				widgetName: 'Margin History',
				widgetIcon: 'history',
				widget: {
					instance: {
						id: 'marginHistory'
					}
				},
				model: {
					accountNumber    : value.accountNumber,
					tankNumber       : value.tankNumber,
					containerKey     : value.containerKey,
					productName      : value.productName,
					customerKey      : value.customerKey,
					deliveryLocation : value.deliveryLocation,
					tradeClass       : value.tradeClass,
					customerName     : value.customerName,
					hierarchyNode    : value.hierarchyNode
				}
			};

			modalService
				.openActionView(actionViewTemplates.marginHistoryDefault, scope, 'marginHistory');
		}


		function openDeliveryHistoryReport(value) {
			var scope = {
				widgetName: 'Delivery History',
				widgetIcon: 'history',
				widget: {
					instance: {
						id: 'deliveryHistory'
					}
				},

				model: {
					accountNumber : value.accountNumber,
					tankNumber    : value.tankNumber,
					containerKey  : value.containerKey,
					productName   : value.productName,
					customerKey   : value.customerKey,
					tankSize      : value.tankSize,
					idealDrop     : value.idealDrop,
					customerName  : value.customerName
				}
			};

			modalService
				.openActionView(actionViewTemplates.deliveryHistoryDefault, scope, 'deliveryHistory');
		}

		function openServiceHistoryReport(item) {
            var scope = {
                widgetName: 'Service History',
                widgetIcon: 'history',
                widget: {
                    instance: {
                        id: 'serviceHistory'
                    }
                },
                model: {
					divisionName  : item.divisionName,
					locationId    : item.locationId,
					locationNumber : item.locationNumber,
					equipmentId   : item.equipment.id,
					equipmentName  : item.equipment.name,
					accountNumber : item.accountNumber,
					customerName  : item.customerName,
					address1      : item.address1,
					address2      : item.address2,
					city          : item.city,
					stateProvince : item.stateProvince,
					postalCode    : item.postalCode,
					customerKey   : item.customerKey
                }
            };

            modalService
                .openActionView(actionViewTemplates.serviceHistoryDefault, scope, 'serviceHistory');
        }

		function openHistory(obj, context){
			switch(context){
				case _context.delivery:
					openDeliveryHistoryReport(obj);
					break;
				case _context.service:
					openServiceHistoryReport(obj);
					break;
				case _context.margin:
					openMarginHistoryReport(obj);
					break;		
				case _context.liquidFuels:
					openLiquidFuelsHistoryReport(obj);
					break;										
				default:
					break;
			}
		}

		function openTmsAdminCustomer(containerKey) {

			const subscriberId = subscriberService.getSubscriberId();

			let tmsAdminWidget;
			let tank;

			return $http({
				url: 'api/widgetPackages/widgets',
				method: 'GET',
				params: {}
			})
				.then (function(res){
					tmsAdminWidget = res.data.find(widget => widget.code == 'paygoTMSAdmin');
				})
				.then(function(){
					return $http({
						url: ('api/subscribers/{0}/containers/monitors/forDealers').format(subscriberId),
						method: 'POST',
						data: {
							searchTerm: undefined,
							dealerIds: undefined,
							inactiveMonitor: undefined,
							unMappedAccounts: undefined,
							containerKey: containerKey
						}
					})
				})
				.then(function(res){
					tank = res.data[0];
				})
				.then(function(){					
					const url = actionViews.getActionView('paygoTMSAdmin');

					const modalScope = {
						widgetName: tmsAdminWidget.name,
						widgetIcon: tmsAdminWidget.icon,
						widgetClass: tmsAdminWidget.widgetClass,
						widgetId: tmsAdminWidget.id,
						widgetCode: tmsAdminWidget.code,
						settings: {},
						model: {
							monitor: tank
						}
					}
					
					modalService.openActionView(url, modalScope, tmsAdminWidget.code);
				});
		}


		return {
			context: _context,
			openAccountInfo: function(item, context){
				const subscriberId = subscriberService.getSubscriberId();

				if(!item.customerKey) return;

				return $http
					.get(('api/subscribers/{0}/customerAccounts/modal/{1}').format(subscriberId, item.customerKey))
					.then(function(result, errors){
						if(errors)
							modalService.openTopBarModal(actionViewTemplates.noRecordFoundTemplate);
						 else 
							modalService
								.openTopBarModal(actionViewTemplates.customerPopover, { item: result.data, queryObj: item, context: context})
								.then(function(obj) {
									if(!obj) return;
									openHistory(obj, context);
									
								}); 
					});				
			},
			retrieveAccountInfo: function(item) {
				return $http.get(('api/subscribers/{0}/customerAccounts/modal/{1}').format($rootScope.user.subscriberId, item.customerKey));
			},
			openTankInfo: function(item, context){
				if(!item.containerKey) return;

				const subscriberId = subscriberService.getSubscriberId();

				return $http
				.get(('api/subscribers/{0}/batches/deliveries/containers/{1}').format(subscriberId, item.containerKey))
					.then(function(result, errors){
						if(errors)
							modalService.openTopBarModal(actionViewTemplates.noRecordFoundTemplate);
						 else 
							modalService
								.openTopBarModal(actionViewTemplates.tankPopover, { item: result.data, queryObj: item, context: context})
								.then(function(obj) {
									if(!obj) return;
									openHistory(obj, context);
								}); 
							
					});	
			},
			retrieveTankInfo: function(item) {
				return $http.get(('api/subscribers/{0}/batches/deliveries/containers/{1}').format($rootScope.user.subscriberId, item.containerKey));
			},

			retrieveDeliveryInfo: function(item) {
				return $http.get(('api/subscribers/{0}/batches/deliveries/{1}/popover').format($rootScope.user.subscriberId, item.deliveryKey));
			},

			retrieveAdjustedPercentInfo: function(item) {
				//NOTE: This should be moved to the deliveries api endppoint....the deliveryLineKey is being replaced by the deliveryKey now...
				return $http.get(('api/subscribers/{0}/batches/deliveries/deliveryLines/{1}/popover').format($rootScope.user.subscriberId, item.deliveryKey));
			},
			openAdjustedPercentInfo : function(item){				
				return $http
					.get(('api/subscribers/{0}/batches/deliveries/deliveryLines/{1}/popover').format($rootScope.user.subscriberId, item.deliveryKey))
					.then(function(result, errors){
						if(errors)
							modalService.openTopBarModal(actionViewTemplates.noRecordFoundTemplate);
						 else 
							modalService.openTopBarModal(actionViewTemplates.adjustedPercentPopover, { item: result.data});
								
					});				
			},
			retrieveKFactorInfo: function(item) {
				//NOTE: This should be moved to the deliveries api endppoint....the deliveryLineKey is being replaced by the deliveryKey now...
				return $http.get(('api/subscribers/{0}/batches/deliveries/deliveryLines/{1}/popover').format($rootScope.user.subscriberId, item.deliveryKey));
			},
			openKFactorInfo: function(item){				
				return $http
					.get(('api/subscribers/{0}/batches/deliveries/deliveryLines/{1}/popover').format($rootScope.user.subscriberId, item.deliveryKey))
					.then(function(result, errors){
						if(errors)
							modalService.openTopBarModal(actionViewTemplates.noRecordFoundTemplate);
						 else 
							modalService.openTopBarModal(actionViewTemplates.kFactorPopover, { item: result.data});
								
					});				
			},

			retrieveCustomerTanks: function(item) {
				return $http.get(('api/subscribers/{0}/customerAccounts/{1}/containers').format($rootScope.user.subscriberId, item.customerKey));
			},

			openServiceLocationInfo: function(item, hideHistory){  	

				return $http
					.get(('api/subscribers/{0}/customerAccounts/modal/{1}/serviceLocation/{2}/equipment').format($rootScope.user.subscriberId, item.customerKey, item.locationId))
					.then(function(equipment){
						item.equipment = equipment.data;
						modalService
							.openTopBarModal(actionViewTemplates.serviceLocationPopover,{ queryObj : item, hideHistory : hideHistory })
							.then(function(result){	
								var obj 	  = result[0];
								var equipment = result[1];
								
								if(!obj) return;
								if(equipment) obj.equipment = equipment;
								openServiceHistoryReport(obj);
							});
					});				
			},

			retrieveServiceLocationInfo: function(item){  
				return $http({
				    url: ('api/subscribers/{0}/service/location').format($rootScope.user.subscriberId), 
				    method: 'GET',
				    params: {
						locationNumber : item.locationNumber,
						accountNumber  : item.accountNumber
					}
				});
			},
			openAcctGainLossCustomersReport: function(isLoss, sourceReason, keys, dates, divisionIds) {
				var scope = {
					widgetName: isLoss ? 'Account Losses - Customers' : 'Account Gains - Customers',
					widgetIcon: 'user',
					widget: {
						instance: {
							id: isLoss ? 'accountLossesCustomers' : 'accountGainsCustomers'
						}
					},
					model: {
						gainLossType : isLoss ? constantsService.gainLossType.loss.value.id : constantsService.gainLossType.gain.value.id,
						isLoss       : isLoss,
						sourceReason : sourceReason || (isLoss ? 'All Reasons' : 'All Sources'),
						dates        : dates,
						keys         : keys,
						divisionIds  : divisionIds
					}
				};

				modalService
					.openActionView(actionViewTemplates.acctGainLossCustomersDefault, scope, isLoss ? 'accountLossesCustomers' : 'accountGainsCustomers');
			},

  			openServiceLaborMargin: function(item) {
            	modalService.openTopBarModal(actionViewTemplates.laborMarginPopover, item);
        	},
        	openServicePartsMargin : function(item) {
            	modalService.openTopBarModal(actionViewTemplates.partsMarginPopover, item);
        	},
        	openServiceCallMargin : function(item) {
            	modalService.openTopBarModal(actionViewTemplates.serviceMarginPopover, { item : item } );
        	},
			openDeliveryHistoryReport : openDeliveryHistoryReport,
			openServiceHistoryReport : openServiceHistoryReport,
			openMarginHistoryReport : openMarginHistoryReport,
			openLiquidFuelsHistoryReport : openLiquidFuelsHistoryReport,
			openTmsAdminCustomer : openTmsAdminCustomer,


			retrieveData: function(item){
				return $q.when({ data: item });
			},

			

			
		};
	}
]);