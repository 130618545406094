angular.module('angus.controllers').controller('shipmentController', [
    '$scope', '$rootScope', '_', 'shipmentService', 'subscriberService', 'constantsService', 'orderService', 'saveAs', 'riderService', 'inventoryManagementService',
        'gridService2', '$q', 'purchaseOrderDeliveryService',
    function ($scope, $rootScope, _, shipmentService, subscriberService, constantsService, orderService, saveAs, riderService, inventoryManagementService, 
        gridService2, $q, purchaseOrderDeliveryService) {
        'use strict';
      
        $scope.isLoading = false;  
        $scope.isCreateMode = false; 
        $scope.isEditMode = false;
        $scope.areShipmentHeadersDropdownsLoading = false;
        $scope.areShipmentDropdownsLoading = false;
        $scope.areShipmentsLoading = false; 
        $scope.areOrderDropdownsLoading = false;
        $scope.alerts = []; 
        $scope.shipmentHeadersDropdown = [];
        $scope.subscribers = []; 
        $scope.shipmentDropdown = [];
        $scope.selectedSubscriber = null;
        $scope.selectedSubscriberKey = null;
        $scope.includeDeleted = 0;
        $scope.dataUpdated = false;
        $scope.shipmentModel = null;
        $scope.onShipmentResults = [];
        $scope.selectedRiderOrderHeader = [];
        $scope.rowStatus = {
            active: 1,
            delete: 3
        } 
        $scope.searchDataLength = 0;
        $scope.onShipmentDataLength = 0;
        $scope.allSearchSelected = true;
        $scope.allOnShipmentSelected = true;
        $scope.removeSelectedMonitorShipmentLoading = false;
        $scope.addMonitorsToShipmentLoading = false;   
        $scope.deliveryHeaders = [];
        $scope.areOrderTypeDropdownsLoading = false;
        $scope.isPackingSlipLoading = false;
        $scope.monitorTypes = [];  
        var shipmentGridOption = {};
        var searchGridOption = {};
        const shipmentType = constantsService.shipmentType;
        const monitorInventoryStatus = constantsService.monitorInventoryStatus;

        function init() {
            $scope.isLoading = true;
            Promise.all([ 
                getAmsSubscribers()
            ])
            .then(function(results) {
                $scope.isLoading = false; 
            });
        } 
 
        //Public Methods ********************************************************************
        $scope.newShipmentModel = function() { 
            $scope.shipmentModel = {  
                riderOrderKey           : null,
                subscriberKey           : null,
                shipmentKey             : null, 
                shipmentHeaderKey       : null,
                shipmentCost            : null,
                shipmentDate            : new Date(),
                shipmentNotes           : '',
                shipmentLines           : [], 
                shipmentTrackingID      : Math.floor(Math.random() * 1000), //setting default to allow to save changes
                shipmentTypeKey         : shipmentType.standard,
                shipmentID              : null,
                updateUser              : ''
            }
        }

        $scope.newShipmentLineModel = function() { 
            var shipmentLine = {
                shipmentLineKey         : null, 
                shipmentItem 	        : 0,  
                shipmentLineNotes       : '',
                shipmentCarrier         : 0,
                shipmentQuantity        : 0, 
                rowStatusKey            : $scope.rowStatus.active,
                shipmentRemaining       : null 
            }  
            $scope.shipmentModel.shipmentLines.push(shipmentLine);
        }

        $scope.searchModel = {
            actualDeliveryDate      : null,
            actualDeliveryDates     : [],
            shippingBoxID           : null,
            boxNumbers              : [],
            monitorTypeKey          : null,
            simCardCarrierKey       : null, 
            serialNumber            : null,
            simNumber               : null
        } 

        $scope.subscriberChange = function() {  
            $scope.isCreateMode = false;
            $scope.subscriber = _.find($scope.subscribers, function(subscriber){
                return subscriber.abosKey == $scope.selectedSubscriberKey;
            });
            $scope.newShipmentModel();  
 
            Promise.all([ 
                getOrderHeader(),
                getMonitorTypes(),
                getSimCardCarriers(),
                getSalesTypes(),
                getShipmentHeader(),
                getPurchaseOrderDelivery()
            ])
            .then(function(results) { 
                $scope.isLoading = false; 
                 
                loadMonitorSearchGrid(); 
                loadMonitorOnShipmentGrid();
                $scope.allSearchSelected = true
                $scope.allOnShipmentSelected = true;
                $scope.cancelHandler();
            });
        };

        $scope.clickPackingSlipHandler = function() {  
            $scope.isPackingSlipLoading = true;
            $scope.shipmentModel.subscriberKey = $scope.selectedSubscriberKey;
            $scope.shipmentModel.subscriber = _.find($scope.subscribers, function(subscriber) {
                return subscriber.abosKey == $scope.selectedSubscriberKey;
            });
            shipmentService.postReport($scope.shipmentModel)
            .then(function(result) { 
                var date = moment().format('YYYYMMDD');
                var subscriberName = $scope.shipmentModel.subscriber.name.replace(/\s/g, '');
                var fileName = `${subscriberName}_${date}_PackingSlip.pdf`;
                prepareDownload(fileName, result, 'application/pdf'); 
                $scope.isPackingSlipLoading = false; 
            })
            .catch(function(err) {
                $scope.alerts.push({type: 'danger', msg: 'Failed to generate the packing slip!'}); 
                $scope.isPackingSlipLoading = false; 
            });  
        } 

        var prepareDownload = function(filename, data, type) {
            saveAs(
                new Blob([data], { type }),
                filename
            );
        }
        
        $scope.shipmentDropDownHandler = function() {
            $scope.isEditMode = true; 
            $scope.shipmentModel.riderOrderKey = null;
            $scope.allSearchSelected = true
            $scope.allOnShipmentSelected = true;
            $scope.addMonitorsToShipmentLoading = false;
            $scope.removeSelectedMonitorShipmentLoading = false;
 
            var shipmentData = _.find($scope.shipmentDropdown, function(shipment) {
                return shipment.shipmentKey == $scope.shipmentModel.shipmentKey;
            });
            var shipmentTrackingID = shipmentData ? shipmentData.shipmentTrackingID : null; 
            getOnShipmentResult(shipmentTrackingID); 
        }  

        var getRiderOrder = function(riderOrderKey) {
            orderService.getOrder(null, riderOrderKey, $scope.includeDeleted)
            .then(function(result) {   
                $scope.riderOrder = result;
                $scope.shipmentModel.orderLines = mapOrderLines($scope.riderOrder.riderOrderLine, $scope.rider.riderLine)
                
                $scope.isLoading = false;
            });
        }
 
        var getRider = function(riderKey, riderOrderKey) {    
            $scope.isLoading = true; 
            riderService.getRider(null, riderKey, $scope.selectedSubscriberKey, $scope.includeDeleted)
            .then(function(result) {  
                $scope.rider = result; 
                getRiderOrder(riderOrderKey)
            }); 
        }   

        var mapOrderLines = function(riderLine, riderLines) {
            var newOrderLines = _.map(riderLine, function(rol,index){
                rol.riderOrderItem = riderLines[index] ? riderLines[index].monitorTypeName : ''; 
                rol.riderOrderCarrier = riderLines[index] ? riderLines[index].simCardCarrierKey : ''; 
                rol.monitorTypeKey = riderLines[index] ? riderLines[index].monitorTypeKey : '';  

                return rol;
            }); 

            return newOrderLines;
        }

        $scope.orderDropDownHandler = function(riderOrderKey, load) { 
            var selectedRider = _.find($scope.selectedRiderOrderHeader, function(order) {
                return order.riderOrderKey == riderOrderKey;
            });
            var riderKey = selectedRider ? selectedRider.riderKey : null;
            if (riderKey) {
                getRider(riderKey, riderOrderKey); 
            }
            if (load) {
                getOnShipmentByRiderOrderResult(); 
            }
        }    

        $scope.submitHandler = function(form, rowStatusKey) {
            if ($scope.shipmentModel.shipmentTypeKey === shipmentType.standard) {
                //If "Standard" is selected as the "Shipment Order Type", then "Order" is required.  
                if ($scope.shipmentModel.riderOrderKey) {
                    submitShipment(form, rowStatusKey);
                }
                else { 
                    $scope.alerts.push({type: 'danger', msg: 'Cannot submit, Sale Shipment Order Type requires Order.'});
                }
            }
            else {
                //If "Beta", "Refurbished", or "Replacement" is selected then Order is NOT required
                submitShipment(form, rowStatusKey);
            }
        }
 
        /* 
        When the Shipment Order Type is selected as "Refurbished" on the Shipment screen: 
        - Update all of the monitor serial numbers on the shipment to a status of "Dealer on Shelf"  
            and Populate "Refurbished Date" with the current date for each serial number
        - Deleting should revert all serial numbers to a status of "Under Repair" instead of "New" 
            and "Refurbished" date should be cleared.
        */
        var updateMonitors = function(status) { 
            if ($scope.shipmentModel.shipmentTypeKey == shipmentType.refurbished) {    
                if (status == $scope.rowStatus.delete) {
                    updateMonitorStatus(monitorInventoryStatus.underRepair, null); 
                }
                else { 
                    updateMonitorStatus(monitorInventoryStatus.dealerOnShelf, moment().toDate()); 
                }
            }
        } 
        
        var updateMonitorStatus = function(monitorInventoryStatus, date) {
            _.forEach($scope.shipmentModel.shipmentLines, function(line) {
                line.monitorInventoryStatusKey = monitorInventoryStatus;
                line.refurbishedDate = date;
            });
        }

        var submitShipment = function(form, rowStatusKey) {
            $scope.shipmentSubmitLoading = true;  
            if ($scope.selectedSubscriberKey) {  
                updateMonitors(rowStatusKey);
                $scope.shipmentModel.subscriberKey = $scope.selectedSubscriberKey;
                $scope.shipmentModel.rowStatusKey = rowStatusKey;  
                $scope.shipmentModel.insertUser = $rootScope.user.username;  
                $scope.shipmentModel.updateUser = $rootScope.user.username;
    
                shipmentService.postShipment($scope.shipmentModel)
                .then(function(result) {
                    var shipmentKey = result && result[0].ShipmentKey;
                    if (shipmentKey > 0) {  
                        if (rowStatusKey == $scope.rowStatus.active) {
                            $scope.alerts.push({ type: 'success', msg: 'Successfully Saved the Shipment.' });  
                        }
                        else { 
                            $scope.alerts.push({ type: 'success', msg: 'Successfully Deleted the Shipment!' }); 
                        }
                        if (form) {
                            form.$setPristine();  
                        }  
                        getShipmentHeader();
                        getOnShipmentResult();  
                    }
                    else { 
                        $scope.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'});  
                    }
                })
                .catch(function(err) {
                    $scope.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'}); 
                }); 
 
                $scope.shipmentSubmitLoading = false;
                $scope.isCreateMode = false; 
                $scope.isEditMode = true; 
            }
            else { 
                $scope.alerts.push({type: 'danger', msg: 'Select a subscriber'});  
            }
        }

        $scope.reset = function() {  
            $scope.isCreateMode = true;
            $scope.isLoading = false;
        }

        $scope.clickAddShipmentHandler = function(form) {   
            $scope.reset(); 
            $scope.cancelHandler(form); 
            $scope.shipmentDropdown = [{ shipmentKey: null,  shipmentID: '<--- Create New --->' }]; 
            $scope.newShipmentModel();
            form.$setDirty();
            $scope.isEditMode = false; 
        }

        $scope.selectAllSearch = function(allSearchSelected) {               
            var selected = _.forEach($scope.searchGrid.options.rowData, function(data) {
                data.monitor = allSearchSelected; 
                return data;
            });       
            $scope.searchGrid.setRows($q.resolve(selected));  
            $scope.searchDataLength = selected.length;  
            $scope.allSearchSelected = !allSearchSelected;
        }
 
        $scope.selectAllOnShipment = function() {
            var selected = _.forEach($scope.onShipmentGrid.options.rowData, function(data) {
                data.monitor = $scope.allOnShipmentSelected; 
                return data;
            });       
            $scope.onShipmentGrid.setRows($q.resolve(selected));  
            $scope.onShipmentDataLength = selected.length;  
            $scope.allOnShipmentSelected = !$scope.allOnShipmentSelected;
        }

        /* Monitor Results */ 
        $scope.search = function() {   
            getSearchResult();   
        } 
 
        $scope.deliveryDateChanged = function() {  
             getShipmentBoxNumber();
        } 

        $scope.addMonitorsToShipment = function() {
            $scope.addMonitorsToShipmentLoading = true;
            $scope.dataUpdated = true; 
            var selected = _.filter($scope.searchGrid.options.rowData, function(data) {
                return data.monitor == true;
            });
            var addedToShipment = false; 
            var availableOrderLines = [];
            if ($scope.shipmentModel.orderLines) {
                availableOrderLines = mergeObjectsWithSameId($scope.shipmentModel.orderLines);
            } 
            if (availableOrderLines && availableOrderLines.length > 0) {
                var activeOrderLines = _.filter(availableOrderLines, function(line) {
                    return line.rowStatusKey == $scope.rowStatus.active
                }); 
                for (var i = 0; i < selected.length; i++) {  
                    var item = selected[i];
                    //get matching monitor type and carrier || allow empty carrier
                    var monitorTypeKeyMismatch = _.find(activeOrderLines, function(line) {
                        return line.monitorTypeKey == item.monitorTypeKey
                                && (item.simcardCarrierKey == line.riderOrderCarrier || item.simcardCarrierKey == null);
                    });  
                    if (!monitorTypeKeyMismatch) {
                        $scope.alerts.push({type: 'danger', msg: 'Failed to add the unit, wrong monitor type and/or carrier type!'}); 
                        $scope.addMonitorsToShipmentLoading = false;
                        break; 
                    }
                    else {
                        var foundRiderOrderLines = _.filter(activeOrderLines, function(line) {
                            return line.monitorTypeKey == item.monitorTypeKey && (!item.simcardCarrierKey || line.riderOrderCarrier == item.simcardCarrierKey);
                        });
                        if (!foundRiderOrderLines) {
                            $scope.alerts.push({type: 'danger', msg: 'Failed to add the unit, Cannot found matching Rider Order!'});  
                        }
                        else {                             
                            var remainingQuantities = 0;
                            _.forEach(foundRiderOrderLines, function(lines) {
                                remainingQuantities += lines.remainingQuantity;
                            });
                            //check if unit can be added, not exceed orderLines 
                            var selectedMonitorIsSameMonitorType = _.filter(activeOrderLines, function(line) {
                                return line.monitorTypeKey == item.monitorTypeKey;
                            }); 
                            var sameSerialNumber = _.filter($scope.shipmentModel.shipmentLines, function(line) {
                                return line.serialNumber == item.serialNumber;
                            });
                            if (selectedMonitorIsSameMonitorType && 
                                remainingQuantities > 0 && 
                                sameSerialNumber.length == 0 ) { 
                                    //foundRiderOrderLines shouldn't be multiple but it so, use first
                                    addToShipmentGrid(item, foundRiderOrderLines[0]);   
                                    addedToShipment = true;
                                    remainingQuantities -= 1;
                            }
                            else { 
                                if (sameSerialNumber.length > 0) {
                                    $scope.alerts.push({type: 'danger', msg: `Failed to add the unit, cannot add same unit with serialnumber!`}); 
                                }
                                else if (remainingQuantities == 0) {
                                    $scope.alerts.push({type: 'danger', msg: `Failed to add the unit, remaining Quantity is 0!`}); 
                                }
                                else {
                                    $scope.alerts.push({type: 'danger', msg: `Failed to add the unit, exceeded Rider Orders quantity!`}); 
                                } 
                                $scope.addMonitorsToShipmentLoading = false;
                                break; 
                            } 
                        }
                    }
                };
            }
            else { 
                //new or order line is not needed
                _.forEach(selected, function(item) {   
                    addToShipmentGrid(item);  
                    addedToShipment = true;  
                }); 
            }
            $scope.addMonitorsToShipmentLoading = false;
            $scope.allSearchSelected = true; 
            $scope.onShipmentDataLength = $scope.shipmentModel.shipmentLines.length;
            getSearchResult();
            if (addedToShipment) {
                $scope.submitHandler(null, $scope.rowStatus.active);
            }    
            
            var rowPromise = $q.resolve($scope.shipmentModel.shipmentLines)
            .then(function(result) {   
                return result;
            });

            var promise = $scope.onShipmentGrid.setRows(rowPromise);
            return promise;
        }

        $scope.shipmentDeletedHandler = function() {     
            getShipmentHeader();     
        }  

        $scope.removeSelectedMonitorShipment = function() {
            $scope.removeSelectedMonitorShipmentLoading = true;
            $scope.dataUpdated = true;
            var gridData = $scope.onShipmentGrid.options.rowData;
            var gridDataUpdated = _.map(gridData, function(data) {
                if (data.monitor == true) {
                    data.rowStatusKey = 3; 
                }
                return data;
            });              
            var notSelected = _.filter(gridData, function(data) {
                return data.monitor == false;
            });  
            $scope.shipmentModel.shipmentLines = gridDataUpdated; 
            $scope.onShipmentDataLength = (notSelected && notSelected.length) || 0;
            var rowPromise = $q.resolve(notSelected)
            .then(function(result) {                    
                var selected = _.filter(gridData, function(data) {
                    return data.monitor == true;
                });  
                getSearchResult(selected); 
                $scope.submitHandler(null, $scope.rowStatus.active);

                $scope.allOnShipmentSelected = true;
                $scope.removeSelectedMonitorShipmentLoading = false;
                return result;
            });

            var promise = $scope.onShipmentGrid.setRows(rowPromise);
            return promise;
        }

        $scope.cancelHandler = function(form, loadHeader) {  
            if (form) {
                form.$setPristine();
                form.$setUntouched();
            } 
             
            $scope.alerts = [];         
            $scope.shipmentModel = null; 
            $scope.isCreateMode = false;
            $scope.isEditMode = false;

            removeGridData();
            if (loadHeader) {
                getShipmentHeader();
            }
        }

        $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
        }

        $scope.clickDeleteOrder = function(formOrder, rowStatus) {
            if(confirm("Are you sure to delete the shipment?")) {
                $scope.submitHandler(formOrder, rowStatus);
            }  
        }

        //Private Methods ********************************************************************
        var addToShipmentGrid = function(item, riderOrderLine) { 
            var onShipment = {
                shipmentLineKey: null,
                riderOrderLineKey: riderOrderLine ? riderOrderLine.riderOrderLineKey : null,
                tankMonitorKey: item.tankMonitorKey,
                shipmentNotes: '',
                rowStatusKey: $scope.rowStatus.active,
                shippingBoxID: item.shippingBoxID,
                shipmentTypeKey: shipmentType.standard,
                serialNumber: item.serialNumber,
                monitorTypeName: item.monitorTypeName,
                simcardCarrierName: item.simcardCarrierName,
                actualDeliveryDate: inventoryManagementService.toDate(item.actualDeliveryDate, 'MM/DD/YYYY'), 
                monitorLocationKey: item.monitorLocationKey,
                monitorLocationDescription: item.monitorLocationDescription,
                monitorInventoryStatusKey: item.monitorInventoryStatusKey,
                monitorInventoryStatusName: item.monitorInventoryStatusName,
                monitor: false
            };
            $scope.shipmentModel.shipmentLines.push(onShipment); 
        }
 
        // Function to merge objects with the same ID
        var mergeObjectsWithSameId = function(arr) {
            var mergedArray = []; 
            for (var i = 0; i < arr.length; i++) {
                var obj = arr[i];
                var existingObj = findObjectById(mergedArray, obj.monitorTypeKey, obj.riderOrderCarrier); 
                if (existingObj) {
                    // Merge properties of existing object with the new object
                    for (var prop in obj) { 
                        if (prop == 'remainingQuantity') { 
                            existingObj[prop] += obj[prop];
                        }
                    }
                } else {
                    // If no existing object with the same ID, add the object to the merged array
                    mergedArray.push(obj);
                }
            }
        
            return mergedArray;
        }
        
        // Helper function to find object by ID in an array
        var findObjectById = function(arr, monitorTypeKey, riderOrderCarrier) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].monitorTypeKey == monitorTypeKey && arr[i].riderOrderCarrier == riderOrderCarrier) 
                {
                    return arr[i];
                }
            }
            return null;
        }  

        var getAmsSubscribers = function() {
            $scope.areSubscriberDropdownsLoading = true;
            subscriberService.getAmsSubscribers()
            .then(function(subscribers){
                $scope.subscribers = _.filter(subscribers, function(subscriber){
                    return subscriber.isPaygo && subscriber.registrationStatusKey === constantsService.registrationStatus.live;
                }); 
                $scope.areSubscriberDropdownsLoading = false;
            }); 
        } 

        var getOrderHeader = function() {    
            var includeDeleted = 0;
            return riderService.getRiderHeader(null, null, $scope.selectedSubscriberKey, includeDeleted)
            .then(function(riderResult) {   
                var activeRiders = []; 
                var activeRiderKeys = [];
                activeRiders = _.filter(riderResult, function(header) {
                    return header.rowStatusKey == $scope.rowStatus.active
                })  
                _.each(activeRiders, function(rider) { 
                    activeRiderKeys.push(rider.riderKey);
                });
                
                $scope.areOrderDropdownsLoading = true;
                return orderService.getOrderHeader(null, null, $scope.selectedSubscriberKey, $scope.includeDeleted)
                .then(function(result) {
                    var headers = []; 
                    headers = _.filter(result, function(header) {
                        return header.rowStatusKey == $scope.rowStatus.active && 
                               _.includes(activeRiderKeys, header.riderKey)
                    })   
                    headers = _.map(headers, function(header){
                        header.orderID = `${header.riderOrderKey} (${inventoryManagementService.toDate(header.riderOrderDate, 'MM/DD/YYYY')})`; 
                        return header;
                    });

                    $scope.orderDropdown = headers; 
                    $scope.selectedRiderOrderHeader = headers;
                    $scope.areOrderDropdownsLoading = false;
                });
            }); 
        } 

        var getShipmentHeader = function() {    
            $scope.areShipmentDropdownsLoading = true;
            return shipmentService.getShipmentHeader($scope.selectedSubscriberKey, $scope.includeDeleted)
            .then(function(headers) {   
                headers = _.map(headers, function(header){
                    header.shipmentID = `${inventoryManagementService.toDate(header.shipmentDate, 'MM/DD/YYYY')}`; 
                    return header;
                });

                $scope.shipmentDropdown = headers;
                $scope.areShipmentDropdownsLoading = false;
            }); 
        }  

        var getPurchaseOrderDelivery = function() {   
            purchaseOrderDeliveryService.getPurchaseOrderDeliveryHeader()
            .then(function(result) {
                var delivery =  _.filter(result, function (item) {  
                    return item.rowStatusKey === $scope.rowStatus.active;
                });  
                var deliveryUpdated = _.map(delivery, function(header){
                    header.actualDeliveryDate = `${inventoryManagementService.toDate(header.actualDeliveryDate, 'MM/DD/YYYY')}`; 
                    return header;
                }); 
                $scope.searchModel.actualDeliveryDates = deliveryUpdated;
            }); 
        } 

        var getMonitorTypes = function() {   
            $scope.areItemsLoading = true;
            return inventoryManagementService.getMonitorTypes($scope.subscriber.id) 
            .then(function(result) {
                $scope.monitorTypes = result;    
                $scope.areItemsLoading = false; 
            }); 
        }  
        
        var getSimCardCarriers = function() {   
            $scope.areCarriersLoading = true; 
            return inventoryManagementService.getSIMCardCarriers()
            .then(function(result) {   
                $scope.simCardCarriers = result.data;  
                $scope.areCarriersLoading = false; 
            }); 
        } 

        var getSalesTypes = function() {
            return inventoryManagementService.getSaleTypes()
            .then(function(result) {   
                $scope.salesTypes = result;   
            }); 
        }

        var getShipmentBoxNumber = function() {
            $scope.searchModel.boxNumbers = [];
            $scope.loadingBoxNumber = true;
            shipmentService.getMonitorSearch(
                $scope.searchModel.monitorTypeKey, 
                $scope.searchModel.shippingBoxID, 
                $scope.searchModel.actualDeliveryDate ? $scope.searchModel.actualDeliveryDate.actualDeliveryDate : null,
                $scope.searchModel.serialNumber, 
                $scope.searchModel.simNumber, 
                $scope.searchModel.simCardCarrierKey
            )
            .then(function(rowData) {    
                _.forEach(rowData, function(data) {
                    if (data.shippingBoxID) {
                        var found = _.find($scope.searchModel.boxNumbers, function(box) {
                            return box == data.shippingBoxID;
                        }); 
                        if (!found) { 
                            $scope.searchModel.boxNumbers.push(data.shippingBoxID);
                        } 
                        $scope.searchModel.boxNumbers = $scope.searchModel.boxNumbers.sort(function(a, b){return b - a});
                    }
                }); 
                $scope.loadingBoxNumber = false;
            });
        }

        var removeDuplicates = function(array, property) {
            return array.filter((value, index, self) =>
              index === self.findIndex((t) => t[property] === value[property])
            );
        }

        var getSearchResult = function(selected){
            var rowPromise = shipmentService.getMonitorSearch(
                $scope.searchModel.monitorTypeKey, 
                $scope.searchModel.shippingBoxID, 
                $scope.searchModel.deliveryDate ? $scope.searchModel.deliveryDate: null,
                $scope.searchModel.serialNumber, 
                $scope.searchModel.simNumber, 
                $scope.searchModel.simCardCarrierKey
            )
            .then(function(result) {   
                if ($scope.searchGrid.options && $scope.searchGrid.options.api) {
                    searchGridOption = _.cloneDeep($scope.searchGrid.options);
                    if (!$scope.searchGrid.gridOptions) {
                        $scope.searchGrid.gridOptions = $scope.searchGrid.options;
                    }
                }
                var newSearchResult = _.map(result, function(rol,index){
                    rol.monitor = {value: false, disabled: false}; 
                    var found = _.find($scope.shipmentModel.shipmentLines, function(line) {
                        return line.tankMonitorKey == rol.tankMonitorKey;
                    });
                    if (found) {
                        rol.monitor = {value: true, disabled: true};
                    }
                    //uncheck if removed from onShipment Grid
                    if (selected) { 
                        _.forEach(selected, function(item) {
                            if (item.tankMonitorKey == rol.tankMonitorKey) {
                                rol.monitor = false;
                            }
                        });  
                    }
                    if (rol.actualDeliveryDate) {
                        rol.actualDeliveryDate = inventoryManagementService.toDate(rol.actualDeliveryDate, 'MM/DD/YYYY');  
                    }
                    if (rol.refurbishedDate) {
                        rol.refurbishedDate = inventoryManagementService.toDate(rol.refurbishedDate, 'MM/DD/YYYY');  
                    }
                    return rol;
                });         
                //remove dups 
                var arrayWithoutDuplicates = removeDuplicates(newSearchResult, "serialNumber");
                $scope.searchDataLength = arrayWithoutDuplicates.length; 
                return arrayWithoutDuplicates;
            });
            var promise = $scope.searchGrid.setRows(rowPromise);
            return promise;
        }
 
        var getColDefsForSearch = function(){ 
            var colDefs = []; 
            
            colDefs.push(gridService2.colDef.createBoolean('monitor','Select','monitor', {edit: true, hide: function(params){} }, {width: 70})); 
            colDefs.push(gridService2.colDef.createText('serialNumber','Serial Number','serialNumber', {}, {width: 120})); 
            colDefs.push(gridService2.colDef.createDate('actualDeliveryDate','Delivery Date','actualDeliveryDate', {}, {width: 120}));
            colDefs.push(gridService2.colDef.createText('shippingBoxID','Box Number','shippingBoxID', {}, {width: 120})); 
            colDefs.push(gridService2.colDef.createText('monitorTypeName','Monitor Type','monitorTypeName', {}, {width: 150})); 
            colDefs.push(gridService2.colDef.createText('simcardCarrierName','Carrier','simcardCarrierName', {}, {width: 100}));  
            colDefs.push(gridService2.colDef.createText('monitorInventoryStatusKey','Inventory Status','monitorInventoryStatusName', {}, {width: 150}));  
            colDefs.push(gridService2.colDef.createDate('refurbishedDate','Refurbished Date','refurbishedDate', {}, {width: 150}));  

            return colDefs;
        } 

        var loadMonitorSearchGrid = function() { 
            var gridParams = { 
                defs: getColDefsForSearch(),
                clicks: {},
                exportOptions: { fileName: 'Search' }
            };
            if (searchGridOption && searchGridOption.api) {
                gridParams.gridOptions = searchGridOption;
            }
            gridService2.createGrid(gridParams)
                .then(function (grid) {
                    grid.setRows(Promise.resolve());
                    $scope.searchGrid = grid; 
                    // $scope.searchGrid.setRows($q.when([]));  
                    $scope.searchDataLength = 0;  
                });
        }

        var getColDefsForOnShipment= function(){ 
            var colDefs = []; 
            var optionValues = []; 
            _.forEach($scope.salesTypes, function(item) {
                optionValues.push({value: item.shipmentTypeKey, text: item.shipmentTypeName });
            });
            colDefs.push(gridService2.colDef.createBoolean('monitor','Select','monitor', {edit: true, hide: function(params){}}, {width: 70}));
            colDefs.push(gridService2.colDef.createText('serialNumber','Serial Number','serialNumber', {}, {width: 120})); 
            colDefs.push(gridService2.colDef.createDate('actualDeliveryDate','Delivery Date','actualDeliveryDate', {}, {width: 120}));
            colDefs.push(gridService2.colDef.createText('shippingBoxID','Box Number','shippingBoxID', {}, {width: 120})); 
            colDefs.push(gridService2.colDef.createText('monitorTypeName','Monitor Type','monitorTypeName', {}, {width: 150})); 
            colDefs.push(gridService2.colDef.createText('simcardCarrierName','Carrier','simcardCarrierName', {}, {width: 100}));  
            colDefs.push(gridService2.colDef.createText('monitorInventoryStatusKey','Inventory Status','monitorInventoryStatusName', {}, {width: 100}));     
            colDefs.push(gridService2.colDef.createDate('refurbishedDate','Refurbished Date','refurbishedDate', {}, {width: 150}));     

            return colDefs;
        } 

        var removeGridData = function() {
            if ($scope.onShipmentGrid) {
                $scope.onShipmentGrid.setRows($q.resolve([]));  
                $scope.onShipmentDataLength = 0;
            }
            if ($scope.searchGrid) { 
                $scope.searchGrid.setRows($q.resolve([]));  
                $scope.searchDataLength = 0;
            }
        }

        var getOnShipmentByRiderOrderResult = function(){
            var rowPromise = shipmentService.getShipment(
                    $scope.shipmentModel.riderOrderKey, 
                    $scope.shipmentModel.shipmentTrackingID, 
                    $scope.selectedSubscriberKey, 
                    $scope.includeDeleted
            )
            .then(function(result) {
                //need to set a date to be able to save
                $scope.shipmentModel.shipmentDate = result.shipmentDate ? new Date(result.shipmentDate) : new Date(); 
                $scope.shipmentModel.shipmentLines = _.map(result.shipmentLines, function(rol,index){
                    rol.monitor = false;  
                    rol.actualDeliveryDate = inventoryManagementService.toDate(rol.actualDeliveryDate, 'MM/DD/YYYY');
                    rol.refurbishedDate = inventoryManagementService.toDate(rol.refurbishedDate, 'MM/DD/YYYY');   
                    return rol;
                }); 
                $scope.shipmentModel.shipmentLines = _.filter($scope.shipmentModel.shipmentLines, function(shipmentLine) {
                    return shipmentLine.rowStatusKey == $scope.rowStatus.active;
                }) 
                $scope.onShipmentDataLength = result.shipmentLines.length; 
                return $scope.shipmentModel.shipmentLines; 
            }); 
            var promise = $scope.onShipmentGrid.setRows(rowPromise);
            return promise; 
        }

        var getOnShipmentResult = function(shipmentTrackingID){
            var rowPromise = shipmentService.getShipment(
                    $scope.shipmentModel.riderOrderKey, 
                    shipmentTrackingID ? shipmentTrackingID : $scope.shipmentModel.shipmentTrackingID, 
                    $scope.selectedSubscriberKey, 
                    $scope.includeDeleted
            )
            .then(function(result) {
                if ($scope.onShipmentGrid.options && $scope.onShipmentGrid.options.api) { 
                    searchGridOption = _.cloneDeep($scope.onShipmentGrid.options); 
                    if (!$scope.onShipmentGrid.gridOptions) {
                        $scope.onShipmentGrid.gridOptions = $scope.onShipmentGrid.options;
                    }
                }
                $scope.shipmentModel = result;
                $scope.shipmentModel.shipmentDate = $scope.shipmentModel.shipmentDate ? new Date($scope.shipmentModel.shipmentDate) : null;
                $scope.orderDropDownHandler($scope.shipmentModel.riderOrderKey, false);  
                $scope.shipmentModel.shipmentLines = _.map($scope.shipmentModel.shipmentLines, function(rol,index){
                    rol.monitor = false;  
                    rol.actualDeliveryDate = inventoryManagementService.toDate(rol.actualDeliveryDate, 'MM/DD/YYYY'); 
                    rol.refurbishedDate = inventoryManagementService.toDate(rol.refurbishedDate, 'MM/DD/YYYY');   
                    return rol;
                }); 
                $scope.shipmentModel.shipmentLines = _.filter($scope.shipmentModel.shipmentLines, function(shipmentLine) {
                    return shipmentLine.rowStatusKey == $scope.rowStatus.active;
                }) 
                $scope.onShipmentDataLength = $scope.shipmentModel.shipmentLines.length; 
                return $scope.shipmentModel.shipmentLines; 
            }); 
            if ($scope.onShipmentGrid) {
                var promise = $scope.onShipmentGrid.setRows(rowPromise);
                return promise; 
            }
            else {
                return null;
            }
        }

        var loadMonitorOnShipmentGrid = function() {
            var gridParams = { 
                defs: getColDefsForOnShipment(),
                clicks: {},
                exportOptions: { fileName: 'Shipment' }
            };
            if (shipmentGridOption && shipmentGridOption.api) {
                gridParams.gridOptions = shipmentGridOption;
            } 
            gridService2.createGrid(gridParams)
                .then(function (grid) {
                    grid.setRows(Promise.resolve());
                    $scope.onShipmentGrid = grid;
                    if (grid.options.api) {
                        shipmentGridOption = _.cloneDeep(grid.options)
                        // $scope.onShipmentGrid.setRows($q.resolve([]));   
                        $scope.onShipmentDataLength = 0;
                    }
                });
        } 

        init(); 
    }
]);
