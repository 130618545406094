angular.module('angus.controllers').controller('serviceRevenueReportContainerCtrl', [
  '$scope', '$rootScope', '$q', '$http',  '_', 'constantsService', 'drillService', 'moment', 'gridService2', 'nodeType', 'gridState', 'restrictedAccessService',
  function($scope, $rootScope, $q, $http,  _, constantsService,  drillService, moment, gridService2, nodeType, gridState, restrictedAccessService) {

    'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

      if($scope.settings.customDate) {
          $scope.dates = {
              startDate: moment($scope.settings.customDate.startDate).format('L') || moment(),
              endDate: moment.utc($scope.settings.customDate.endDate).format('L') || moment()
          }
      } else {
          $scope.dates = {
              startDate: $scope.model.periodStartDate || moment(),
              endDate: $scope.model.lastPostingDate || moment(),
              lastPostingDate: $scope.model.lastPostingDate || moment()
          }
      }

      $scope.filter =  {
          hierarchyNodeId : $scope.settings.hierarchyNodeId,
          serviceRevenueCategory  : $scope.model.serviceRevenueCategory && $scope.model.serviceRevenueCategory.value ? [$scope.model.serviceRevenueCategory.value.toString()] : [],
          techs: $scope.settings.techs ? $scope.settings.techs.value : [],
          callReasons: $scope.settings.callReasons ? $scope.settings.callReasons.value : [],
          usePostingDate: $scope.settings.usePostingDate.value
      };

      $scope.getServiceRevenue = function() {

          var params = {
              divisionId: $scope.filter.hierarchyNodeId,
              sd: $scope.dates.startDate.format(),
              ed: $scope.dates.endDate.format(),
              sr: $scope.filter.serviceRevenueCategory,
              t: $scope.filter.techs,
              cr: $scope.filter.callReasons,
              usePostingDate: $scope.filter.usePostingDate
          };


          if(!params.sr.length || params.sr.length === 0){ 
            params.sr = _.map(constantsService.serviceRevenueCategories.enums, function(cat){
                return cat.value.id;
            });
          }

        var rowPromise = $http({
                url: 'api/subscribers/{0}/service/revenues/list'.format($rootScope.user.subscriberId),
                method: 'POST',
                data: params
          }).then(function(result){
              return result.data;
          });

          var promise = $scope.grid.setRows(rowPromise);

      //$scope.promiseMonitor = new PromiseMonitor(promise);
      return promise;
      };



     function getColClicks(){
          return {
              accountNumber   : {
                onClick : function(row){ drillService.openAccountInfo(row); }
              },
              locationNumber    : {
                onClick : function(row){ drillService.openServiceLocationInfo(row); }
              },
              partsMargin : {
                onClick : function(row){ if(row.id) drillService.openServicePartsMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id || params.data.partsMargin == 0;
                }
              },
              laborMargin : {
                onClick : function(row){ if(row.id) drillService.openServiceLaborMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id || params.data.laborMargin == 0;
                }
              },
              callMargin : {
                onClick : function(row){ if(row.id) drillService.openServiceCallMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id || params.data.callMargin == 0;
                }
              },

          };
      }

      function getColDefs(){
          var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
          var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];


          colDefs.push(gridService2.colDef.createText('serviceTypeName','Service Type','serviceTypeName', { rowGroupIndex : nextIndex, hide : true } ));
          colDefs.push(gridService2.colDef.createText('primaryTech','Primary Tech','primaryTech', { rowGroupIndex : nextIndex + 1, hide : true, cellClass : ['center']} ));

          colDefs.push(gridService2.colDef.createDate('date','Date','date'));
          colDefs.push(gridService2.colDef.createDate('useAccountingDate','Accounting Date','useAccountingDate', { hide: true, sort: 'asc' }));
          colDefs.push(gridService2.colDef.createDate('postingDate','Posting Date','postingDate', { hide: true, sort: 'asc' }));

          colDefs.push(gridService2.colDef.createText('workOrderNumber','Work Order Number','workOrderNumber', { cellClass : ['center']} ));
          colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center']} ));
          colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName'));

          colDefs.push(gridService2.colDef.createText('locationNumber','Service Location','locationNumber', { cellClass : ['center']} ));
          colDefs.push(gridService2.colDef.createText('departmentName','Department Name','departmentName', { cellClass : ['center'] } ));
          colDefs.push(gridService2.colDef.createText('callReason','Call Reason','callReason', { cellClass : ['center']} ));

          colDefs.push(gridService2.colDef.createBoolean('underContractFlag','Contract Coverage','underContractFlag'));

          colDefs.push(gridService2.colDef.createNumber('numberOfCalls','Number Of Calls','numberOfCalls', {aggFunc : 'sum'}));
          colDefs.push(gridService2.colDef.createNumber('laborHoursOnSite','On Site Hours','laborHoursOnSite', {aggFunc : 'sum'}));
          colDefs.push(gridService2.colDef.createNumber('laborHoursBilled','Billed Hours','laborHoursBilled', {aggFunc : 'sum'}));
          if(!$scope.restrictedAccess.revenue)


          if(!$scope.restrictedAccess.costAndMargin)
            colDefs.push(gridService2.colDef.createCurrency('partsMargin','Parts Margin','partsMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.costAndMargin)
            colDefs.push(gridService2.colDef.createCurrency('laborMargin','Labor Margin','laborMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.revenue)
            colDefs.push(gridService2.colDef.createCurrency('callBilled','Revenue','callBilled', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.costAndMargin)
            colDefs.push(gridService2.colDef.createCurrency('callMargin','Call Margin','callMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          return colDefs;

      }

      var nodeColDefs;

      $q.all([
        nodeType.getDivisionColDefs(subscriberId),
        restrictedAccessService.getAccess()
      ]).spread(function(colDefs, access){
        $scope.restrictedAccess = access.restrictedAccess;
        nodeColDefs = colDefs;

          var gridParams = {
              gridState     : gridState(subscriberId, $scope.widgetCode),
              defs          : getColDefs(),
              clicks        : getColClicks(),
              exportOptions : { fileName: 'Service Revenue' }
          };
          return gridService2.createGrid(gridParams);
        })
        .then(function(grid){
            $scope.grid = grid;
            $scope.getServiceRevenue();
        });

  }
]);
