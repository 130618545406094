angular.module('angus.controllers').controller('adeptPerformanceAnalysisSettingsController', [
    '$q', '$rootScope', '$scope', 'adeptService', 'adeptPerformanceAnalysisService', 'containerService', 'deliveryTypeService', 'hierarchyService', 'productsService', 'promiseMonitor',
    function($q, $rootScope, $scope, adeptService, adeptPerformanceAnalysisService, containerService, deliveryTypeService, hierarchyService, productsService, promiseMonitor){
        $scope.promiseMonitor = new promiseMonitor();

        $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.timePeriods = adeptPerformanceAnalysisService.getTimePeriods();

        $scope.persistedSettings = {
            adeptGroupIds: [],
            hierarchyNodeId: [],
            productCategoryIds: [],
            deliveryTypeIds: [],
            containerTypeId: $scope.widget.instance.settings.containerTypeId.value
        }

        $scope.useAdeptGroupChanged = useAdeptGroupChanged;
        $scope.containerTypeChanged = () => $scope.persistedSettings.containerTypeId = $scope.widget.instance.settings.containerTypeId.value;

        $scope.adeptGroupChanged = () => loadContainerTypes();
        $scope.productCategoriesChanged = () => loadContainerTypes();
        $scope.deliveryTypesChanged = () => loadContainerTypes();
        $scope.hierarchyNodeChanged = () => loadContainerTypes();

        function useAdeptGroupChanged() {            
            if ($scope.widget.instance.settings.useAdeptGroup.value) {
                /*Store the values of the disabled controls in case they toggle the Use ADEPT Group option again before leaving the screen. */
                $scope.persistedSettings.hierarchyNodeId = $scope.widget.instance.settings.hierarchyNodeId;
                $scope.persistedSettings.productCategoryIds = $scope.widget.instance.settings.productCategoryIds.value;
                $scope.persistedSettings.deliveryTypeIds = $scope.widget.instance.settings.deliveryTypeIds.value;
                
                /* Clear out values for Disabled controls. */
                $scope.widget.instance.settings.hierarchyNodeId = [];
                $scope.widget.instance.settings.productCategoryIds.value = [];
                $scope.widget.instance.settings.deliveryTypeIds.value = [];

                /* If we had a Persisted ADEPT Group then use it. */
                $scope.widget.instance.settings.adeptGroupIds.value = $scope.persistedSettings.adeptGroupIds;
            }
            else {
                /* Clear out values for Disabled controls. Store the values in case they toggle the Use ADEPT Group option again before leaving the screen. */
                $scope.persistedSettings.adeptGroupIds = $scope.widget.instance.settings.adeptGroupIds.value;
                $scope.widget.instance.settings.adeptGroupIds.value = [];

                /* If we had a Persisted settings then use them. */
                $scope.widget.instance.settings.hierarchyNodeId = $scope.persistedSettings.hierarchyNodeId;
                $scope.widget.instance.settings.productCategoryIds.value = $scope.persistedSettings.productCategoryIds;
                $scope.widget.instance.settings.deliveryTypeIds.value = $scope.persistedSettings.deliveryTypeIds;
            }

            loadContainerTypes();

        }

        function loadContainerTypes() {
            $scope.containerTypes = [];
            $scope.widget.instance.settings.containerTypeId.value = null;
            
            const useAdeptGroup = $scope.widget.instance.settings.useAdeptGroup.value;

            const subscriberId = $scope.subscriberId;

            let promise;
            if(useAdeptGroup)
            {
                const adeptGroupIds = $scope.widget.instance.settings.adeptGroupIds.value;

                promise = containerService.getContainerTypes(subscriberId, undefined, undefined, undefined, undefined, adeptGroupIds);
            }
            else {
                const divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId)
                const productCategoryIds = $scope.widget.instance.settings.productCategoryIds.value;
                const deliveryTypeIds = $scope.widget.instance.settings.deliveryTypeIds.value;

                promise = containerService.getContainerTypes(subscriberId, divisionIds, productCategoryIds, undefined, deliveryTypeIds, undefined);
            }

            $scope.areContainerTypesLoading = true;
            
            return promise.then(function(containerTypes){
                $scope.containerTypes = containerTypes;

                const persistedContainerTypeId = $scope.persistedSettings.containerTypeId;

                if (persistedContainerTypeId)
                {
                    const containerTypeMatch = containerTypes.find(containerType => containerType.id == persistedContainerTypeId);

                    if (containerTypeMatch) {
                        $scope.widget.instance.settings.containerTypeId.value = containerTypeMatch.id.toString(); /* Not entirely sure why this needs to be a String. Angular?? */
                    }
                }

                $scope.areContainerTypesLoading = false;
            });
        }

        const promise = $q.all([
            adeptService.getAdeptGroups($scope.subscriberId)
                .then(function(adeptGroups) {
                    $scope.adeptGroups = adeptGroups;
                }),
            deliveryTypeService.getDeliveryTypes($scope.subscriberId)
                .then(function(deliveryTypes) {
                    $scope.deliveryTypes = deliveryTypes;
                }),
            productsService.getProducts($scope.subscriberId)
                .then(function(productCategories) {
                    $scope.productCategories = productCategories;
                }),
            loadContainerTypes()
        ]);

        $scope.promiseMonitor.monitor(promise);

    }
])