angular.module('angus.services').service('adeptPerformanceAnalysisService', [
    '$http',
    function($http) {
        
        function getTimePeriods() {
            return {
                month: 1,
                year: 2
            }
        }

        function getYears(numberOfYears){
            const currentDate = new Date(); 

             /* Build Years array 5 years back */
            const years = [currentDate.getFullYear()];
            
            for(let i = 1; i < numberOfYears; i++) {
                years.push(years[i-1] - 1);
            }

            return years;
        }      
        

        function getSummary(subscriberId, timePeriod, month, year, adeptGroupIds, divisionIds, productCategoryIds, deliveryTypeIds, containerTypeId, includeMonitoredTanks, includeGroupOptimizedTransactions){
            /* Compute Start and End Date */
            const timePeriods = getTimePeriods();
            let startDate, endDate;
            

            if (timePeriod == timePeriods.month) {
                startDate = new Date(year, month - 1, 1);
                endDate = new Date(year, month, 0);
            }
            else {
                startDate = new Date(year - 1, month - 1, 1);
                endDate = new Date(year, month, 0);
            }
              
           
            return queryPerformanceAnalysisSummary(subscriberId, startDate, endDate, adeptGroupIds, divisionIds, productCategoryIds, deliveryTypeIds, containerTypeId, includeMonitoredTanks, includeGroupOptimizedTransactions)
                .then(function(res) {
                    return res.data;
                });
        }

        function getDetail(subscriberId, month, year, adeptGroupIds, divisionIds, productCategoryIds, deliveryTypeIds, containerTypeId, includeMonitoredTanks, includeGroupOptimizedTransactions){

             /* Compute Start and End Date */
            const startDate = new Date(year - 1, month - 1, 1);
            const endDate = new Date(year, month, 0);

            return queryPerformanceAnalysisDetail(subscriberId, startDate, endDate, adeptGroupIds, divisionIds, productCategoryIds, deliveryTypeIds, containerTypeId, includeMonitoredTanks, includeGroupOptimizedTransactions)
                .then(function(res) {
                    return res.data;
                });
        }
        
        function queryPerformanceAnalysisSummary(subscriberId, startDate, endDate, adeptGroupIds, divisionIds, productCategoryIds, deliveryTypeIds, containerTypeId, includeMonitoredTanks, includeGroupOptimizedTransactions) {
            return $http({
                url: ('api/subscribers/{0}/adept/performanceAnalysisSummary').format(subscriberId),
                method: 'POST',
                data: {
                    startDate: startDate ? startDate.toLocaleString(): null,
                    endDate: endDate ? endDate.toLocaleString(): null,
                    adeptGroupIds: adeptGroupIds,
                    divisionIds: divisionIds,
                    productCategoryIds: productCategoryIds, 
                    deliveryTypeIds: deliveryTypeIds,
                    containerTypeId: containerTypeId,
                    includeMonitoredTanks: includeMonitoredTanks || 'Y',
                    includeGroupOptimizedTransactions: includeGroupOptimizedTransactions || 'Y'
                }
            });
        }

        function queryPerformanceAnalysisDetail(subscriberId, startDate, endDate, adeptGroupIds, divisionIds, productCategoryIds, deliveryTypeIds, containerTypeId, includeMonitoredTanks, includeGroupOptimizedTransactions) {
            return $http({
                url: ('api/subscribers/{0}/adept/performanceAnalysisDetail').format(subscriberId),
                method: 'POST',
                data: {
                    startDate: startDate ? startDate.toLocaleString(): null,
                    endDate: endDate ? endDate.toLocaleString(): null,
                    adeptGroupIds: adeptGroupIds,
                    divisionIds: divisionIds,
                    productCategoryIds: productCategoryIds, 
                    deliveryTypeIds: deliveryTypeIds,
                    containerTypeId: containerTypeId,
                    includeMonitoredTanks: includeMonitoredTanks || 'Y',
                    includeGroupOptimizedTransactions: includeGroupOptimizedTransactions || 'Y'
                }
            }); 
        }       
        return {
            getTimePeriods: getTimePeriods,
            getYears: getYears,
            getSummary: getSummary,
            getDetail: getDetail
        };
    }
]);