angular.module('angus.controllers').controller('usersCtrl', [
    '$scope', '$rootScope', '$http', '$window', '$location', '$timeout', 'modalService', 'hierarchyHelperService', 'hierarchyService', 'actionViewTemplates', '_', 'authenticationService', 'fluentRest', 'Paging', '$q', 'constantsService', 'promiseMonitor', '$mdToast', 'dashboardsService', 'restrictedAccessService',
    function($scope, $rootScope, $http, $window, $location, $timeout, modalService, hierarchyHelperService, hierarchyService, actionViewTemplates, _, authenticationService, fluentRest, Paging, $q, constantsService, promiseMonitor, $mdToast, dashboardsService, restrictedAccessService) {

        'use strict';

        $scope.users = [];

        // $scope.getUsers= function(pageNumber){
        $scope.getUsers = function() {

            var params = {
                sortBy: $scope.sortBy,
                ascending: $scope.ascending,
                searchTerm: $scope.searchTerm
            };
            // var url = fluentRest.api().subscribers($scope.subscriberId).users().toString();
            // return $scope.paging.getPage( url , pageNumber, params ).then( function( users ) { $scope.users = users.items; $scope.usersLoaded = true; });
            return fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .users()
                .get(params)
                .then(function(users) {
                    _.forEach(users, function(user){
                        if(user.lastSignOn){
                            var lastSignOn = new Date(user.lastSignOn);
                            user.lastSignOnDisplay = lastSignOn.toLocaleDateString() + ' ' + lastSignOn.toLocaleTimeString();
                        }
                        else {
                            user.lastSignOnDisplay = 'Never';
                        }

                        user.isQa = user.role ==='qa';
                        user.isSysAdmin = user.role ==='sysadmin';
                    });
                    $scope.users = users;
                    $scope.usersLoaded = true;
                });
        }

        function getAccess(){
          return restrictedAccessService
            .getAccess()
            .then(function(access){
              $scope.dashboardAccess = access.adminAccess.dashboards
            });
        }
        function getHierarchy(subscriberId) {
            var promises = _.map(constantsService.hierarchyType.enums, function(type) {
                return fluentRest
                    .api()
                    .subscribers(subscriberId)
                    .hierarchy()
                    .nodes()
                    .get({
                        hierarchyType: type.key
                    })
                    .then(function(nodes) {
                        $scope.nodes[type.key] = hierarchyService.convertDataForEaseOfUse(nodes);
                    })

            })



            return $q.all(promises);
        }

        function getSecurityGroups(subscriberId) {

            return $http
                .get(('api/subscribers/{0}/securityGroups').format(subscriberId))
                .success(function(securityGroups) {
                    $scope.securityGroups = securityGroups;
                });
        }

        function getDashboards(securityGroupId) {

        }

        function init() {
            $scope.isLoading = false;
            $scope.isSysAdmin = $rootScope.user.role === 'sysadmin';
            $scope.isAdmin = $rootScope.user.role === 'admin';
            $scope.sortBy = 'username';
            $scope.ascending = true;
            // $scope.paging = new Paging(25);
            $scope.nodes = {};

            $q.all([
                getAccess(),
                $scope.getUsers(),
                getHierarchy($scope.subscriberId),
                getSecurityGroups($scope.subscriberId)
            ]);

        }

        init();




        function updateUsers(user) {
            var existingUser = _.find($scope.users, function(u) {
                return (u.userId == user.userId);
            });

            if (!existingUser)
                $scope.users.push(user);
            else {
                if (user.isAdmin) user.role = 'admin';
                else user.role = 'user';

                _.merge(existingUser, user, true);
            }
        }

        $scope.pushDashboard = function(selectedUser) {
            return dashboardsService
                .init($scope.subscriberId, selectedUser.securityGroupId)
                .then(function(data) {
                    var dashboards = data.dashboards;

                    if (!dashboards || dashboards.length == 0) {
                        var toast = $mdToast
                            .simple()
                            .textContent('There are no default dashboards set up yet for this user\'s security group.')
                            .hideDelay(5000);

                        $mdToast.show(toast);
                        return;
                    }

                    return $http
                        .get(('api/subscribers/{0}/users?pageSize=0&pageNumber=0').format($scope.subscriberId))
                        .then(function(users) {
                            var scope = {
                                users: _.filter(users.data, function(user) {
                                    return user.securityGroupId == selectedUser.securityGroupId;
                                }),
                                dashboards: dashboards,
                                selectedUsers: [selectedUser.userId],
                                selectedDashboards: [],
                                lockdownSettings: null
                            };

                            return modalService
                                .openTopBarModal(actionViewTemplates.subRegPushDefaultDashboards, scope)
                                .spread(function(selectedUsers, selectedDashboards, isLocked) {
                                    if (!selectedUsers || !selectedDashboards) return;

                                    var pushes = _.map(selectedUsers, function(userId) {
                                        return $http.get(('api/dashboards/{0}/userDashboards/{1}').format(selectedDashboards, userId))
                                            .then(function(userDashboards) {
                                                $scope.userDashboards = userDashboards.data;

                                                return userDashboards.data;
                                            })
                                            .then(function() {
                                                return $http
                                                    .post('api/dashboards/', {
                                                        ids: selectedDashboards,
                                                        order: 0,
                                                        userId: userId,
                                                        isLocked: isLocked
                                                    });
                                            })
                                            .then(function(response) {
                                                _.forEach(response.data, function(dashboards) {
                                                    _.forEach($scope.userDashboards, function(userDashboards) {
                                                        if(dashboards.name === userDashboards.name) {
                                                            $http.delete(('api/dashboards/{0}/userDashboards/{1}').format(selectedDashboards, userDashboards.id))
                                                                .then(function() {
                                                                    _.remove(userDashboards, function(dashboard) { return dashboard.id });
                                                                });
                                                        }
                                                    });
                                                });

                                            });
                                    });

                                    return $q
                                        .all(pushes)
                                        .then(function() {
                                            var toast = $mdToast
                                                .simple()
                                                .textContent('The dashboards have been pushed.')
                                                .hideDelay(5000);

                                            $mdToast.show(toast);
                                        });
                                });
                        });
                });
        };

        $scope.impersonate = function(user) {

            var currentWindow = 'currentWindow';
            var newWindow = 'newWindow';


            fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .users(user.userId)
                .impersonate()
                .get()
                .then(function(user) {
                    var scope = {
                        currentWindow: currentWindow,
                        newWindow: newWindow,
                        username: user.username
                    };

                    return modalService
                        .openTopBarModal('templates/global/html/impersonationSelection.html', scope)
                        .then(function(selection) {
                            if (!selection) return;
                            else if (selection == newWindow) {
                                $window.open(('/#/Impersonate?subscriberId={0}&userId={1}').format($scope.subscriberId, user.userId), '_blank');
                                return;
                            } else if (selection == currentWindow) {
                                authenticationService.impersonateUser($rootScope.user.username, user);
                                $window.location.reload(true);
                            }
                        });
                })
                .catch(function(err) {
                    console.log('fail!: ', err);
                });
        };

        $scope.openUserModal = function(user) {

            if (user) {
                user.isAdmin = user.role === 'admin';
                user.password = undefined;
                user.confirmPassword = undefined;
            }

            var hierarchyTypes = [constantsService.hierarchyType.division, constantsService.hierarchyType.deliveryCenter, constantsService.hierarchyType.serviceCenter];

            if ($scope.subscriber.isPaygo)
                hierarchyTypes.push(constantsService.hierarchyType.dealer);

            var scope = {
                editUser: user !== undefined ? user : {},
                nodes: $scope.nodes,
                hierarchyTypes: hierarchyTypes,
                securityGroups: $scope.securityGroups,
                alerts: [],
                monitor: new promiseMonitor(),
                closeAlert: function(index) {
                    this.alerts.splice(index, 1);
                },
                saveUser: function(user) {
                    var that = this;
                    if (user) {

                        var endpoint = fluentRest.api().subscribers($scope.subscriberId).users(user.userId);

                        var promise = user.userId ? endpoint
                            .put(user)
                            .then(function() {
                                that.close(user);
                            }) : endpoint
                            .post(user)
                            .then(function(newUser) {
                                that.close(newUser.data);
                            });



                        promise
                            .catch(function(err) {
                                var alerts = _.map(err, function(e) {
                                    return {
                                        type: 'danger',
                                        msg: '<strong>Error!</strong> ' + e.msg
                                    };
                                });

                                [].push.apply(that.alerts, alerts);
                            });

                        that.monitor.monitor(promise);

                        return promise;
                    } else
                        that.close();
                },
                openHierarchy: function(hierarchyType) {
                    var that = this;
                    var nodes = $scope.nodes[hierarchyType.key];

                    hierarchyService.openModal(null, nodes, hierarchyType)
                        .then(function(nodeId) {
                            that.editUser.hierarchy[hierarchyType.key].nodeId = nodeId;
                        });
                }
            };


            modalService
                .openTopBarModal(actionViewTemplates.subRegEditUsers, scope)
                .then(function(user) {

                    $scope.getUsers();
                    // updateUsers(user);

                })
                .finally(function() {
                    $scope.isLoading = false;
                });
        };

        $scope.confirmRemoveContact = function(user) {
            var scope = {
                title: 'Delete ' + user.username + '?',
                message: 'Are you sure you would like to delete ' + user.firstName + ' ' + user.lastName + ' from this subscriber?'
            };

            modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                .then(function(result) {
                    if (result)
                        $http.delete(('api/subscribers/{0}/users/{1}').format($scope.subscriberId, user.userId))
                        .success(function() {
                            _.remove($scope.users, function(u) {
                                return u.userId == user.userId;
                            });
                            $scope.isLoading = false;
                        });
                });
        };

        $scope.getSecurityGroupForUser = function(user) {
            return _.find($scope.securityGroups, function(securityGroup) {
                return securityGroup.id == user.securityGroupId;
            });
        };



        $scope.toggleLock = function(user) {
            $http.put(('api/subscribers/{0}/users/{1}/lock').format($scope.subscriberId, user.userId), {
                    lockUser: user.isLocked ? 0 : 1
                })
                .success(function(isLocked) {
                    user.isLocked = isLocked;
                });
        };

    }
]);
