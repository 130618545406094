angular.module('angus.services').factory('editProfileService', [
    '$rootScope', '$http', 'ProfileResource', function ($rootScope, $http, profileResource) {
        return {
            updatePassword: function (currentPassword, newPassword, confirmNewPassword, successCb, failureCb) {
                $http.post('/changePassword', { currentPassword: currentPassword, newPassword: newPassword, confirmNewPassword: confirmNewPassword })
                .success(function() {
                    successCb();
                }).error(function(err) {
                    failureCb(err);
                });
            }
        };
    }
]);

