angular.module('angus.controllers').controller('serviceCallsReportContainerCtrl', [
  '$scope', '$rootScope', '$http',  '$q', '_', 'constantsService', 'drillService', 'moment', 'gridService2', 'nodeType', 'gridState', 'restrictedAccessService',
  function($scope, $rootScope, $http, $q,  _, constantsService,  drillService, moment, gridService2, nodeType, gridState, restrictedAccessService) {

    'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

      if($scope.settings.customDate) {
          $scope.dates = {
              startDate: moment($scope.settings.customDate.startDate).format('L') || moment(),
              endDate: moment.utc($scope.settings.customDate.endDate).format('L') || moment()
          }
      } else {
          $scope.dates = {
              startDate: $scope.model.periodStartDate || moment(),
              endDate: $scope.model.lastPostingDate || moment(),
              lastPostingDate: $scope.model.lastPostingDate || moment()
          }
      }

      $scope.filter =  {
        hierarchyNodeId: $scope.settings.hierarchyNodeId,
        callReasons: $scope.settings.callReasons ? $scope.settings.callReasons.value : [],
        techs: $scope.settings.techs ? $scope.settings.techs.value : [],
        serviceRevenueCategory: $scope.settings.serviceRevenueCategory.value,
        usePostingDate: $scope.settings.usePostingDate.value
      };


      $scope.getServiceCalls = function(){

          var params = {
              divisionId: $scope.filter.hierarchyNodeId,
              sd: $scope.dates.startDate.format(),
              ed: $scope.dates.endDate.format(),
              cr: $scope.filter.callReasons,
              t: $scope.filter.techs,
              sc: $scope.filter.serviceRevenueCategory,
              usePostingDate: $scope.filter.usePostingDate
          };

        var rowPromise = $http({
                url: 'api/subscribers/{0}/service/calls/list'.format(subscriberId),
                method: 'POST',
                data: params
          }).then(function(result){
            var records = result.data;

            _.forEach(records, function(record) {
                record.percentOfMargin = (function(margin, sales) {
                    if(margin == 0 || sales == 0 || margin > sales) {
                        return 0;
                    }
                    return margin / sales * 100;
                })(record.callMargin, record.callBilled);
                
                /* A bug with AG Grid appears to force us to use the same ColId and Field names. So duplicate the Date field here. */
                record.dateMonth = record.date;
                record.dateYear = record.date;
            });

            return records;
          });

          var promise = $scope.grid.setRows(rowPromise);

      //$scope.promiseMonitor = new PromiseMonitor(promise);
      return promise;
      }



     function getColClicks(){
          return {
              accountNumber   : {
                onClick : function(row){ drillService.openAccountInfo(row); }
              },
              locationNumber    : {
                onClick : function(row){ drillService.openServiceLocationInfo(row); }
              },
              partsMargin : {
                onClick : function(row){ if(row.id) drillService.openServicePartsMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id;
                }
              },
              laborMargin : {
                onClick : function(row){ if(row.id) drillService.openServiceLaborMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id;
                }
              },
              callMargin : {
                onClick : function(row){ if(row.id) drillService.openServiceCallMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id;
                }
              },

          };
      }

      function getColDefs(){

          var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];


          colDefs.push(gridService2.colDef.createDate('date','Date','date', { sort: 'asc' }));
          colDefs.push(gridService2.colDef.createMonth('dateMonth','Month','dateMonth', { hide: true }));
          colDefs.push(gridService2.colDef.createYear('dateYear','Year','dateYear', { hide: true }));
          colDefs.push(gridService2.colDef.createDate('useAccountingDate','Accounting Date','useAccountingDate', { hide: true, sort: 'asc' }));
          colDefs.push(gridService2.colDef.createDate('postingDate','Posting Date','postingDate', { hide: true, sort: 'asc' }));

          colDefs.push(gridService2.colDef.createText('workOrderNumber','Work Order Number','workOrderNumber', { cellClass : ['center']} ));
          colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center']} ));
          colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName'));
          colDefs.push(gridService2.colDef.createText('customerEmail','Email Address','customerEmail', {hide:true} ));

          colDefs.push(gridService2.colDef.createText('locationNumber','Service Location','locationNumber', { cellClass : ['center']} ));
          colDefs.push(gridService2.colDef.createText('departmentName','Department Name','departmentName', { cellClass : ['center'], hide: true} ));
          colDefs.push(gridService2.colDef.createText('callReason','Call Reason','callReason', { cellClass : ['center']} ));
          colDefs.push(gridService2.colDef.createText('primaryTech','Primary Tech','primaryTech', { cellClass : ['center']} ));
          colDefs.push(gridService2.colDef.createBoolean('underContractFlag','Contract Coverage','underContractFlag'));

          colDefs.push(gridService2.colDef.createNumber('numberOfCalls','Number Of Calls','numberOfCalls', {aggFunc : 'sum'}));
          colDefs.push(gridService2.colDef.createBoolean('flatRateFlag','Flat Rate','flatRateFlag'));
          colDefs.push(gridService2.colDef.createNumber('laborHoursOnSite','On Site Hours','laborHoursOnSite', {aggFunc : 'sum'}));
          if(!$scope.restrictedAccess.revenue)
            colDefs.push(gridService2.colDef.createNumber('laborHoursBilled','Billed Hours','laborHoursBilled', { aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.costAndMargin)
            colDefs.push(gridService2.colDef.createCurrency('partsMargin','Parts Margin','partsMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.costAndMargin)
            colDefs.push(gridService2.colDef.createCurrency('laborMargin','Labor Margin','laborMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.revenue)
            colDefs.push(gridService2.colDef.createCurrency('callBilled','Revenue','callBilled', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.costAndMargin)
            colDefs.push(gridService2.colDef.createCurrency('callMargin','Call Margin','callMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.revenue && !$scope.restrictedAccess.costAndMargin) {
            colDefs.push(gridService2.colDef.createPercentage('percentOfMargin','% Margin','percentOfMargin', {aggFunc : 'none'}, {decimalPlaces: 1 }));
          }

          return colDefs;
      }

      var nodeColDefs;

      $q.all([
          nodeType.getDivisionColDefs(subscriberId),
          restrictedAccessService.getAccess(),
        ])
        .spread(function(colDefs, access){
            nodeColDefs = colDefs;
            $scope.restrictedAccess = access.restrictedAccess;
            var gridParams = {
                gridState     : gridState(subscriberId, $scope.widgetCode),
                defs          : getColDefs(),
                clicks        : getColClicks(),
                exportOptions : { fileName: 'Service Calls' }
            };
            return gridService2.createGrid(gridParams);
        })
        .then(function(grid){
            $scope.grid = grid;
            $scope.getServiceCalls();
        });

  }
]);
