angular.module('angus.controllers').controller('ngServerTestController', [
    '$scope', 'subscriberService',
    function($scope, subscriberService) {

        $scope.onLoadSubscribers = function() {
            subscriberService.listV2()
                .then(function(subscribers) {
                    $scope.subscribers = subscribers;
                });
        }
    }
]);