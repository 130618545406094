'use strict';


var saveAs = (function(view) {
    // IE <10 is explicitly unsupported
    if (typeof view === "undefined" || typeof navigator !== "undefined" && /MSIE [1-9]\./.test(navigator.userAgent)) {
        return;
    }
    var
        doc = view.document
        // only get URL when necessary in case Blob.js hasn't overridden it yet
        , get_URL = function() {
            return view.URL || view.webkitURL || view;
        }
        , save_link = doc.createElementNS("http://www.w3.org/1999/xhtml", "a")
        , can_use_save_link = "download" in save_link
        , click = function(node) {
            var event = new MouseEvent("click");
            node.dispatchEvent(event);
        }
        , is_safari = /constructor/i.test(view.HTMLElement) || view.safari
        , is_chrome_ios =/CriOS\/[\d]+/.test(navigator.userAgent)
        , throw_outside = function(ex) {
            (view.setImmediate || view.setTimeout)(function() {
                throw ex;
            }, 0);
        }
        , force_saveable_type = "application/octet-stream"
        // the Blob API is fundamentally broken as there is no "downloadfinished" event to subscribe to
        , arbitrary_revoke_timeout = 1000 * 40 // in ms
        , revoke = function(file) {
            var revoker = function() {
                if (typeof file === "string") { // file is an object URL
                    get_URL().revokeObjectURL(file);
                } else { // file is a File
                    file.remove();
                }
            };
            setTimeout(revoker, arbitrary_revoke_timeout);
        }
        , dispatch = function(filesaver, event_types, event) {
            event_types = [].concat(event_types);
            var i = event_types.length;
            while (i--) {
                var listener = filesaver["on" + event_types[i]];
                if (typeof listener === "function") {
                    try {
                        listener.call(filesaver, event || filesaver);
                    } catch (ex) {
                        throw_outside(ex);
                    }
                }
            }
        }
        , auto_bom = function(blob) {
            // prepend BOM for UTF-8 XML and text/* types (including HTML)
            // note: your browser will automatically convert UTF-16 U+FEFF to EF BB BF
            if (/^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(blob.type)) {
                return new Blob([String.fromCharCode(0xFEFF), blob], {type: blob.type});
            }
            return blob;
        }
        , FileSaver = function(blob, name, no_auto_bom) {
            if (!no_auto_bom) {
                blob = auto_bom(blob);
            }
            // First try a.download, then web filesystem, then object URLs
            var
                filesaver = this
                , type = blob.type
                , force = type === force_saveable_type
                , object_url
                , dispatch_all = function() {
                    dispatch(filesaver, "writestart progress write writeend".split(" "));
                }
                // on any filesys errors revert to saving with object URLs
                , fs_error = function() {
                    if ((is_chrome_ios || (force && is_safari)) && view.FileReader) {
                        // Safari doesn't allow downloading of blob urls
                        var reader = new FileReader();
                        reader.onloadend = function() {
                            var url = is_chrome_ios ? reader.result : reader.result.replace(/^data:[^;]*;/, 'data:attachment/file;');
                            var popup = view.open(url, '_blank');
                            if(!popup) view.location.href = url;
                            url=undefined; // release reference before dispatching
                            filesaver.readyState = filesaver.DONE;
                            dispatch_all();
                        };
                        reader.readAsDataURL(blob);
                        filesaver.readyState = filesaver.INIT;
                        return;
                    }
                    // don't create more object URLs than needed
                    if (!object_url) {
                        object_url = get_URL().createObjectURL(blob);
                    }
                    if (force) {
                        view.location.href = object_url;
                    } else {
                        var opened = view.open(object_url, "_blank");
                        if (!opened) {
                            // Apple does not allow window.open, see https://developer.apple.com/library/safari/documentation/Tools/Conceptual/SafariExtensionGuide/WorkingwithWindowsandTabs/WorkingwithWindowsandTabs.html
                            view.location.href = object_url;
                        }
                    }
                    filesaver.readyState = filesaver.DONE;
                    dispatch_all();
                    revoke(object_url);
                }
            ;
            filesaver.readyState = filesaver.INIT;
            if (can_use_save_link) {
                object_url = get_URL().createObjectURL(blob);
                setTimeout(function() {
                    save_link.href = object_url;
                    save_link.download = name;
                    click(save_link);
                    dispatch_all();
                    revoke(object_url);
                    filesaver.readyState = filesaver.DONE;
                });
                return;
            }
            fs_error();
        }
        , FS_proto = FileSaver.prototype
        , saveAs = function(blob, name, no_auto_bom) {
            return new FileSaver(blob, name || blob.name || "download", no_auto_bom);
        }
    ;
    // IE 10+ (native saveAs)
    if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
        return function(blob, name, no_auto_bom) {
            name = name || blob.name || "download";
            if (!no_auto_bom) {
                blob = auto_bom(blob);
            }
            return navigator.msSaveOrOpenBlob(blob, name);
        };
    }
    FS_proto.abort = function(){};
    FS_proto.readyState = FS_proto.INIT = 0;
    FS_proto.WRITING = 1;
    FS_proto.DONE = 2;
    FS_proto.error =
        FS_proto.onwritestart =
            FS_proto.onprogress =
                FS_proto.onwrite =
                    FS_proto.onabort =
                        FS_proto.onerror =
                            FS_proto.onwriteend =
                                null;
    return saveAs;
}(
    window
));

angular.module('angus.constants')
    .constant('_', window._) //lodash
    .constant('$', window.$) //jQuery
    .constant('FileReader', window.FileReader)
    .constant('moment', window.moment)
    .constant('saveAs', window.saveAs)
    .service('actionViewTemplates', function() {

        return {
            //Global
            sideNav: 'templates/authenticated/layout/window/windowSideNav.html',
            hierarchy: 'templates/widgetPartials/html/hierarchy/display.html',
            datePicker: 'templates/global/html/datePicker.html',
            monthPicker: 'templates/global/html/monthPicker.html',
            tableHeader: 'templates/global/html/tableHeader.html',
            showHideColumns: 'templates/global/html/showHideColumns.html',
            modalGraphContainer: 'templates/global/html/modalGraph/modalGraphContainer.html',
            modalDualGraphContainer: 'templates/global/html/modalGraph/modalDualGraphContainer.html',
            modalAcctGainLossByTankHistory: 'templates/global/html/modalGraph/modalAcctGainLossByTankHistoryGraph.html',
            modalAcctGainLossByTankContent: 'templates/global/html/modalGraph/modalAcctGainLossByTankGraphContent.html',
            modalGraphContent: 'templates/global/html/modalGraph/modalGraphContent.html',
            saveReportFilter: 'templates/global/html/saveReportFilter.html',
            confirmDialog: 'templates/global/html/confirmDialog.html',
            terms: 'templates/global/html/terms.html',


            newDashboard: 'templates/authenticated/dashboard/newDashboard.template.html',

            //Widget Settings
            widgetSettingsModal: 'templates/authenticated/dashboard/widget/widgetSettingsModal.template.html',

            //Widget Library
            chooseWidgetView: 'templates/widgetLibrary/html/widgetViewModal.html',
            addDashboard: 'templates/widgetLibrary/html/addDashboard.html',


            //Global Popover
            // deliveryPopover                : 'deliveryPopover.html',
            // customerPopover                : 'customerPopover.html',
            // tankPopover                    : 'tankPopover.html',
            // adjustedPercentPopover         : 'adjustedPercentPopover.html',
            // kFactorPopover                 : 'kFactorPopover.html',

            //global modal views
            deliveryPopover: 'templates/global/html/modalViews/deliveryPopover.html',
            contractPopover: 'templates/global/html/modalViews/contractPopover.html',
            customerPopover: 'templates/global/html/modalViews/customerPopover.html',
            tankPopover: 'templates/global/html/modalViews/tankPopover.html',
            adjustedPercentPopover: 'templates/global/html/modalViews/adjustedPercentPopover.html',
            kFactorPopover: 'templates/global/html/modalViews/kFactorPopover.html',
            partsMarginPopover: 'templates/global/html/modalViews/partsMarginPopover.html',
            laborMarginPopover: 'templates/global/html/modalViews/laborMarginPopover.html',
            serviceMarginPopover: 'templates/global/html/modalViews/serviceMarginPopover.html',
            serviceLocationPopover: 'templates/global/html/modalViews/serviceLocationPopover.html',
            etlStatusPopover: 'templates/global/html/modalViews/etlStatusPopover.html',

            //contentTemplate
            customerTank: 'containerContent.html',

            //SubReg
            subRegDefault: 'templates/widgets/subReg/html/actionViews/default.html',
            subRegRegistration: 'templates/widgets/subReg/html/actionViews/registration.html',
            subRegForm: 'templates/widgets/subReg/html/actionViews/subRegForm.html',
            subRegConnection: 'templates/widgets/subReg/html/actionViews/connection.html',
            subRegConnectionAddSourceSystem: 'templates/widgets/subReg/html/actionViews/addSourceSystem.html',
            subRegConnectionAddEditConnection: 'templates/widgets/subReg/html/actionViews/addEditConnection.html',
            subRegUsers: 'templates/widgets/subReg/html/actionViews/users.html',
            subRegConsumerUsers: 'templates/widgets/subReg/html/actionViews/consumerUsers.html',
            subRegDefaultDashboards : 'templates/widgets/subReg/html/actionViews/defaultDashboards.html',
            subRegPushDefaultDashboards : 'templates/widgets/subReg/html/actionViews/defaultDashboards.push.html',
            subRegMapping: 'templates/widgets/subReg/html/actionViews/mapping.html',
            subRegHierarchy: 'templates/widgets/subReg/html/actionViews/hierarchy.html',
            subRegEditNodeTypes: 'templates/widgets/subReg/html/actionViews/editNodeTypes.html',
            subRegHierarchyConfirmRemoveNode: 'templates/widgets/subReg/html/actionViews/confirmRemoveNode.html',
            subRegSettings: 'templates/widgets/subReg/html/actionViews/settings.html',
            subRegEditSettings: 'templates/widgets/subReg/html/actionViews/editSettings.html',
            subRegSettingsCascadeDialog: 'templates/widgets/subReg/html/actionViews/settingsCascadeDialog.html',
            subRegViewAddresses: 'templates/widgets/subReg/html/actionViews/viewAddresses.html',
            subRegEditAddresses: 'templates/widgets/subReg/html/actionViews/editAddresses.html',
            subRegConfirmDeleteAddress: 'templates/widgets/subReg/html/actionViews/confirmDeleteAddress.html',
            subRegViewContacts: 'templates/widgets/subReg/html/actionViews/viewContacts.html',
            subRegEditContacts: 'templates/widgets/subReg/html/actionViews/editContacts.html',
            subRegEditTMSContact: 'templates/widgets/subReg/html/actionViews/editTMSContact.html',
            subRegEditUsers: 'templates/widgets/subReg/html/actionViews/editUsers.html',
            subRegEditConsumerUser: 'templates/widgets/subReg/html/actionViews/editConsumerUser.html',
            subRegConfirmDeleteContact: 'templates/widgets/subReg/html/actionViews/confirmDeleteContact.html',
            subRegConfirmDeleteUser: 'templates/widgets/subReg/html/actionViews/confirmDeleteUser.html',
            subRegProducts: 'templates/widgets/subReg/html/actionViews/products.html',
            subRegAddEditProductCategory: 'templates/widgets/subReg/html/actionViews/addEditProductCategory.html',
            subRegAddEditReportingProduct: 'templates/widgets/subReg/html/actionViews/addEditReportingProducts.html',
            subRegAddEditDeliveryType: 'templates/widgets/subReg/html/actionViews/addEditDeliveryTypes.html',
            subRegAddEditPricePlan: 'templates/widgets/subReg/html/actionViews/addEditPricePlan.html',
            subRegCopyBudgets: 'templates/widgets/subReg/html/actionViews/copyBudgets.html',
            subRegCopyBudgetsTGL: 'templates/widgets/subReg/html/actionViews/copyBudgetsTGL.html',
            subRegGenerateBudgets: 'templates/widgets/subReg/html/actionViews/generateBudgets.html',
            subRegDeliveries: 'templates/widgets/subReg/html/actionViews/deliveries.html',
            subRegDriverExclusion: 'templates/widgets/subReg/html/actionViews/driverExclusion.html',


            subRegPostingCodes: 'templates/widgets/subReg/html/actionViews/postingCodes.html',
            subRegCategoryCodes: 'templates/widgets/subReg/html/actionViews/categoryCodes.html',
            subRegTradeClass: 'templates/widgets/subReg/html/actionViews/tradeClass.html',
            subRegAddEditTradeClass: 'templates/widgets/subReg/html/actionViews/addEditTradeClass.html',
            subRegPricePlan: 'templates/widgets/subReg/html/actionViews/pricePlan.html',
            subRegCallReason: 'templates/widgets/subReg/html/actionViews/callReason.html',
            subRegSecurityGroup: 'templates/widgets/subReg/html/actionViews/securityGroup.html',
            subRegAddEditSecurityGroup: 'templates/widgets/subReg/html/actionViews/addEditSecurityGroup.html',
            subRegDeliveryType: 'templates/widgets/subReg/html/actionViews/deliveryType.html',


            subRegAwdMappings: 'templates/widgets/subReg/html/actionViews/awdMappings.html',
            subRegEsysDivision: 'templates/widgets/subReg/html/actionViews/esysDivision.html',
            subRegCargasIntegrationSettings: 'templates/widgets/subReg/html/actionViews/cargasIntegrationSettings.html',

            subRegBudgets: 'templates/widgets/subReg/html/actionViews/budgets.html',
            subRegDownloadFinanceBudget: 'templates/widgets/subReg/html/actionViews/downloadFinanceBudget.template.html',
            subRegImportBudgets: 'templates/widgets/subReg/html/actionViews/importBudgets.template.html',

            subRegMainBudgets: 'templates/widgets/subReg/html/actionViews/budgets.template.html',
            subRegBudgetProductCategories : 'templates/widgets/subReg/html/actionViews/budgetProductCategories.html',
            subRegBudgetTradeClassCategories : 'templates/widgets/subReg/html/actionViews/budgetTradeClassCategories.html',
            subRegBudgetFinanceStandards : 'templates/widgets/subReg/html/actionViews/financeStandards.template.html',
            subRegTankGainLossBudgets: 'templates/widgets/subReg/html/actionViews/tankGainLossBudgets.html',
            subRegCosts: 'templates/widgets/subReg/html/actionViews/costs.html',
            subRegNewCostSetting: 'templates/widgets/subReg/html/actionViews/newCostSetting.html',
            subRegEditCostSetting: 'templates/widgets/subReg/html/actionViews/editCostSetting.html',
            subRegNewCostDiff: 'templates/widgets/subReg/html/actionViews/newCostDiff.html',
            subRegNewCostOverride: 'templates/widgets/subReg/html/actionViews/newCostOverride.html',
            subRegNewHedgePosition: 'templates/widgets/subReg/html/actionViews/newHedgePosition.html',

            subRegPaygo: 'templates/widgets/subReg/html/actionViews/paygo.html',
            subRegPaygoAddersModal: 'templates/widgets/subReg/html/actionViews/paygoAddersModal.html',
            subRegPaygoAdders: 'templates/widgets/subReg/html/actionViews/paygoAdders.html',
            subRegPaygoProducts: 'templates/widgets/subReg/html/actionViews/paygoProducts.html',
            subRegPaygoBillingCycle: 'templates/widgets/subReg/html/actionViews/paygoBillingCycle.html',
            subRegPaygoExceptionSettings: 'templates/widgets/subReg/html/actionViews/paygoExceptionSettings.html',
            subRegTMSExceptionSettings: 'templates/widgets/subReg/html/actionViews/tmsExceptionSettings.html',

            subRegAddEditCostCategories: 'templates/widgets/subReg/html/actionViews/addEditCostCategories.html',
            subRegAddEditCostCategoryMapping: 'templates/widgets/subReg/html/actionViews/addEditCostCategoryMappings.html',

            subRegGainLossGoals: 'templates/widgets/subReg/html/actionViews/gainLossGoals.html',
            subRegDeliveryGoals: 'templates/widgets/subReg/html/actionViews/deliveryGoals.html',
            subRegServiceGoals: 'templates/widgets/subReg/html/actionViews/serviceGoals.html',
            subRegServiceRevenueGoals: 'templates/widgets/subReg/html/actionViews/serviceRevenueGoals.html',
            subRegDegreeDayGoals: 'templates/widgets/subReg/html/actionViews/degreeDayGoals.html',

            subRegService: 'templates/widgets/subReg/html/actionViews/service.html',
            subRegHierarchyTreeControl: 'templates/widgets/subReg/html/actionViews/hierarchyTreeControl.template.html',
            subRegSelectHierarchyNode: 'templates/widgets/subReg/html/actionViews/selectHierarchyNode.html',

            subRegAdept: 'templates/widgets/subReg/html/actionViews/adept.html',
            ngServerTest: 'templates/widgets/subReg/html/actionViews/ngServerTest.html',

            //Paygo TMS Admin
            paygoTMSAdminMonitors: 'templates/widgets/paygoTMSAdmin/html/actionViews/monitors.html',
            paygoTMSAdminMonitor: 'templates/widgets/paygoTMSAdmin/html/actionViews/monitor.html',
            paygoTMSAdminMonitorEdit: 'templates/widgets/paygoTMSAdmin/html/actionViews/editMonitor.html', 
            paygoTMSAdminActionView: 'templates/widgets/paygoTMSAdmin/html/actionViews/paygoTMSAdminActionView.html',
            paygoTMSAdminDefault: 'templates/widgets/paygoTMSAdmin/html/actionViews/default.html',
            paygoTMSAdminConfirmDeleteCustomer: 'templates/widgets/paygoTMSAdmin/html/actionViews/confirmDeleteCustomer.html', 
 
            //PaygoExceptions
            paygoExceptionsSubscribers: 'templates/widgets/paygoExceptions/html/actionViews/subscribers.html',
            paygoExceptionsSubscriber: 'templates/widgets/paygoExceptions/html/actionViews/subscriber.html',
            paygoExceptionsActionView: 'templates/widgets/paygoExceptions/html/actionViews/default.html',
            paygoExceptionsActionView1: 'templates/widgets/paygoExceptions/html/actionViews/paygoExceptionsActionView.html',
            paygoExceptionsComments: 'templates/widgets/paygoExceptions/html/actionViews/comments.html',
            paygoExceptionsConfirm: 'templates/widgets/paygoExceptions/html/actionViews/confirm.html',    

            //PaygoCustRec 
            paygoCustRecImport                  : 'templates/widgets/paygoCustRec/html/actionViews/importCustomers.html',
            paygoCustRecCustomers               : 'templates/widgets/paygoCustRec/html/actionViews/customers.html',
            paygoCustRecCustomer                : 'templates/widgets/paygoCustRec/html/actionViews/customer.html', 
            paygoCustRecAddComment              : 'templates/widgets/paygoCustRec/html/actionViews/add-comment.html',
            paygoCustRecViewPhoto             : 'templates/widgets/paygoCustRec/html/actionViews/view-photo.html',
            paygoCustRecViewComments            : 'templates/widgets/paygoCustRec/html/actionViews/view-comments.html',
            paygoCustRecViewSignificantComments : 'templates/widgets/paygoCustRec/html/actionViews/view-significant-comments.html',

            //TMS Monitor Admin
            tmsMonitorAdminMonitors: 'templates/widgets/tmsMonitorAdmin/html/actionViews/monitors.html',
            tmsMonitorAdminMonitor: 'templates/widgets/tmsMonitorAdmin/html/actionViews/monitor.html',
            tmsMonitorAdminMonitorEdit: 'templates/widgets/tmsMonitorAdmin/html/actionViews/monitorEdit.html',
            tmsMonitorAdminActionView: 'templates/widgets/tmsMonitorAdmin/html/actionViews/tmsMonitorAdminActionView.html',
            tmsMonitorAdminDefault: 'templates/widgets/tmsMonitorAdmin/html/actionViews/default.html',

            tmsMonitorAddEditMonitorProperties: 'templates/widgets/tmsMonitorAdmin/html/actionViews/addEditMonitorProperties.html',
            tmsMonitorEditMonitorMapping: 'templates/widgets/tmsMonitorAdmin/html/actionViews/editMonitorMapping.html',
            dailyLevelsEditMonitorMapping: 'templates/widgets/tmsMonitorAdmin/html/actionViews/dailyLevelsEditMonitorMapping.html',
 
            //Imports
            importFile: 'templates/authenticated/imports/importFile.html',
            monitorImportMessages: 'templates/authenticated/imports/monitorInventoryImportMessages.html',
  
            tmsWeeklyTankMonitoringUsage : 'templates/widgets/tmsWeeklyQueries/html/actionViews/default.html', 

            //Active Monitors Report 
            activeTankMonitors : 'templates/tankMonitor/inventoryManagement/reports/activeTankMonitors/activeTankMonitorsView.html', 

            //Monitor Inventory
            inventoryManagementController : 'templates/tankMonitor/inventoryManagement/inventoryManagementView.html', 

            // TMS Monitor Compliance
            tmsMonitorComplianceReportContainer: 'templates/widgets/tmsMonitorCompliance/html/actionViews/tmsMonitorComplianceActionView.html',

            //DriverPerf
            driverPerfDefault: 'templates/widgets/driverPerf/html/actionViews/default.html',
            driverPerfReportList: 'templates/widgets/driverPerf/html/actionViews/reportListContent.html',
            driverPerfReportContainer: 'templates/widgets/driverPerf/html/actionViews/reportContainer.html',
            driverPerfReportContent: 'templates/widgets/driverPerf/html/actionViews/reportContent.html',

            //DeliveryPerf
            deliveryPerfDefault: 'templates/widgets/deliveryPerf/html/actionViews/default.html',
            deliveryPerfReportList: 'templates/widgets/deliveryPerf/html/actionViews/reportListContent.html',
            deliveryPerfReportContainer: 'templates/widgets/deliveryPerf/html/actionViews/reportContainer.html',
            deliveryPerfReportContent: 'templates/widgets/deliveryPerf/html/actionViews/reportContent.html',

            //DeliveryEff
            deliveryEffDefault: 'templates/widgets/deliveryEff/html/actionViews/default.html',
            deliveryEffReportList: 'templates/widgets/deliveryEff/html/actionViews/reportListContent.html',
            deliveryEffReportContainer: 'templates/widgets/deliveryEff/html/actionViews/reportContainer.html',
            deliveryEffReportContent: 'templates/widgets/deliveryEff/html/actionViews/reportContent.html',

            //MarginAnalysis
            marginAnalysisDefault: 'templates/widgets/marginAnalysis/html/actionViews/default.html',
            marginAnalysisReportList: 'templates/widgets/marginAnalysis/html/actionViews/reportListContent.html',
            marginAnalysisReportContainer: 'templates/widgets/marginAnalysis/html/actionViews/reportContainer.html',
            marginAnalysisReportContent: 'templates/widgets/marginAnalysis/html/actionViews/reportContent.html',

            //MarginTransaction
            marginTransactionDefault: 'templates/widgets/marginTrans/html/actionViews/default.html',
            marginTransactionReportContainer: 'templates/widgets/marginTrans/html/actionViews/reportContainer.html',
            marginTransactionReportContent: 'templates/widgets/marginTrans/html/actionViews/reportContent.html',

            //MarginHistory
            marginHistoryDefault: 'templates/widgets/marginHistory/html/actionViews/default.html',
            marginHistoryReportContainer: 'templates/widgets/marginHistory/html/actionViews/reportContainer.html',
            marginHistoryReportContent: 'templates/widgets/marginHistory/html/actionViews/reportContent.html',

            //LiquidFuels
            liquidFuelsDefault             : 'templates/widgets/liquidFuels/html/actionViews/default.html',
            liquidFuelsReportList          : 'templates/widgets/liquidFuels/html/actionViews/reportListContent.html',
            liquidFuelsReportContainer     : 'templates/widgets/liquidFuels/html/actionViews/reportContainer.html',
            liquidFuelsReportContent       : 'templates/widgets/liquidFuels/html/actionViews/reportContent.html',

            //LiquidFuelsHistory
            liquidFuelsHistoryDefault              : 'templates/widgets/liquidFuelsHistory/html/actionViews/default.html',
            liquidFuelsHistoryReportContainer      : 'templates/widgets/liquidFuelsHistory/html/actionViews/reportContainer.html',
            liquidFuelsHistoryReportContent        : 'templates/widgets/liquidFuelsHistory/html/actionViews/reportContent.html',


            //DeliveryHistory
            deliveryHistoryDefault: 'templates/widgets/deliveryHistory/html/actionViews/default.html',
            deliveryHistoryReportList: 'templates/widgets/deliveryHistory/html/actionViews/reportListContent.html',
            deliveryHistoryReportContainer: 'templates/widgets/deliveryHistory/html/actionViews/reportContainer.html',
            deliveryHistoryReportContent: 'templates/widgets/deliveryHistory/html/actionViews/reportContent.html',

            //batchDeliveries
            batchDeliveriesDefault: 'templates/widgets/batchDeliveries/html/actionViews/default.html',
            batchDeliveriesReportList: 'templates/widgets/batchDeliveries/html/actionViews/reportListContent.html',
            batchDeliveriesReportContainer: 'templates/widgets/batchDeliveries/html/actionViews/reportContainer.html',
            batchDeliveriesReportContent: 'templates/widgets/batchDeliveries/html/actionViews/reportContent.html',


            //Weather
            weatherHourlyDetailContent: 'templates/widgets/weather/html/actionViews/hourlyDetailContent.html',
            weatherHourlyDetailContainer: 'templates/widgets/weather/html/actionViews/hourlyDetailContainer.html',

            //HddTracker
            hddTrackerDefault: 'templates/widgets/hddtracker/html/actionViews/default.html',
            hddTrackerReportList: 'templates/widgets/hddtracker/html/actionViews/reportListContent.html',
            hddTrackerReportContainer: 'templates/widgets/hddtracker/html/actionViews/reportContainer.html',
            hddTrackerReportContent: 'templates/widgets/hddtracker/html/actionViews/reportContent.html',

            //Propane Tank Utilization
            tankUtilDefault: 'templates/widgets/tankUtil/html/actionViews/default.html',
            tankUtilReportList: 'templates/widgets/tankUtil/html/actionViews/reportListContent.html',
            tankUtilReportContainer: 'templates/widgets/tankUtil/html/actionViews/reportContainer.html',
            tankUtilReportContent: 'templates/widgets/tankUtil/html/actionViews/reportContent.html',

            //Average Gallons Delivered By Tank Size
            avgGalByTankSizeDefault: 'templates/widgets/avgGalByTankSize/html/actionViews/default.html',
            avgGalByTankSizeReportList: 'templates/widgets/avgGalByTankSize/html/actionViews/reportListContent.html',
            avgGalByTankSizeReportContainer: 'templates/widgets/avgGalByTankSize/html/actionViews/reportContainer.html',
            avgGalByTankSizeReportContent: 'templates/widgets/avgGalByTankSize/html/actionViews/reportContent.html',

            //Average Gallons Delivery History
            avgGalDeliveryHistoryDefault: 'templates/widgets/avgGalDeliveryHistory/html/actionViews/default.html',
            avgGalDeliveryHistory: 'templates/widgets/avgGalDeliveryHistory/html/actionViews/avgGalDeliveryHistoryActionView.html',

            //Budget Variance
            budgetVarDefault: 'templates/widgets/budgetVar/html/actionViews/default.html',
            budgetVarContainer: 'templates/widgets/budgetVar/html/actionViews/container.html',
            budgetVarContent: 'templates/widgets/budgetVar/html/actionViews/content.html',
            budgetVarReportList: 'templates/widgets/budgetVar/html/actionViews/reportListContent.html',

            //Santa Budget Variance
            santaBudgetVarDefault: 'templates/widgets/santaBudgetVar/html/actionViews/default.html',
            santaBudgetVarContainer: 'templates/widgets/santaBudgetVar/html/actionViews/santaBudgetVarContainer.html',

            //Santa Prior Period Variance
            santaPriorPeriodVarDefault: 'templates/widgets/santaPriorPeriodVar/html/actionViews/default.html',
            santaPriorPeriodVarContainer: 'templates/widgets/santaPriorPeriodVar/html/actionViews/santaPriorPeriodVarContainer.html',

            //Propane Variance
            propaneVarDefault: 'templates/widgets/propaneVar/html/actionViews/default.html',
            propaneVarContainer: 'templates/widgets/propaneVar/html/actionViews/propaneVarContainer.html',

            //Blossman Sales Report
            salesReportDefault: 'templates/widgets/salesReport/html/actionViews/default.html',
            salesReportContainer: 'templates/widgets/salesReport/html/actionViews/salesReportContainer.html',

            // Margin To Budget
            marginToBudgetDefault: 'templates/widgets/marginToBudget/html/actionViews/default.html',
            marginToBudgetDetailView: 'templates/widgets/marginToBudget/html/actionViews/marginToBudgetDetailView.html',

            // Volume To Budget
            volumeToBudgetDefault: 'templates/widgets/volumeToBudget/html/actionViews/default.html',
            volumeToBudgetDetailView: 'templates/widgets/volumeToBudget/html/actionViews/volumeToBudgetDetailView.html',

            // Growth To Budget
            growthToBudgetDefault: 'templates/widgets/growthToBudget/html/actionViews/default.html',
            growthToBudgetDetailView: 'templates/widgets/growthToBudget/html/actionViews/growthToBudgetDetailView.html',

            // Gains To Budget
            gainsToBudgetDefault: 'templates/widgets/gainsToBudget/html/actionViews/default.html',
            gainsToBudgetContainer: 'templates/widgets/gainsToBudget/html/actionViews/gainsToBudgetDetailView.html',

            //Hedge Positions
            hedgePosContainer: 'templates/widgets/hedgePos/html/actionViews/hedgePosContainer.html',

            // A/R Summary FuelPak
            // arSummaryFuelPakDefault: 'templates/widgets/arSummaryFuelPak/html/actionViews/default.html',
            // arsummaryFuelPakReportList: 'templates/widgets/arsummaryFuelPak/html/actionViews/reportListContent.html',
            // arSummaryFuelPakReportContainer: 'templates/widgets/arSummaryFuelPak/html/actionViews/reportContainer.html',
            // arSummaryFuelPakReportContent: 'templates/widgets/arSummaryFuelPak/html/actionViews/reportContent.html',
            // arSummaryFuelPakAggRowContent: 'templates/widgets/arSummaryFuelPak/templates/reportAggRowTemplate.html',

            //A/R Summary BSG
            arSummaryBsgDefault: 'templates/widgets/arSummaryBsg/html/actionViews/default.html',
            arSummaryBsgReportList: 'templates/widgets/arSummaryBsg/html/actionViews/reportListContent.html',
            // arSummaryReportContainer: 'templates/widgets/arSummary/html/actionViews/reportContainer.html',
            arSummaryBsgReportContent: 'templates/widgets/arSummaryBsg/html/actionViews/reportContent.html',
            // arSummaryAggRowContent: 'templates/widgets/arSummary/templates/reportAggRowTemplate.html',

            bsgServiceMarginDefault: 'templates/widgets/bsgServiceMargin/html/actionViews/default.html',
            bsgServiceMarginActionView: 'templates/widgets/bsgServiceMargin/html/actionViews/bsgServiceMarginActionView.html',

            fdsServiceMarginDefault: 'templates/widgets/fdserviceMargin/html/actionViews/default.html',
            fdsServiceMarginActionView: 'templates/widgets/fdsServiceMargin/html/actionViews/fdsServiceMarginActionView.html',

            //A/R Summary
            arSummaryDefault: 'templates/widgets/arSummary/html/actionViews/default.html',
            arSummaryReportList: 'templates/widgets/arSummary/html/actionViews/reportListContent.html',
            arSummaryReportContent: 'templates/widgets/arSummary/html/actionViews/reportContent.html',

            //Acct Gains by Src
            acctGainBySrcDefault: 'templates/widgets/acctGainBySrc/html/actionViews/default.html',
            acctGainBySrcContainer: 'templates/widgets/acctGainBySrc/html/actionViews/reportContainer.html',
            acctGainBySrcContent: 'templates/widgets/acctGainBySrc/html/actionViews/reportContent.html',

            //Acct Losses by Rsn
            acctLossByRsnDefault: 'templates/widgets/acctLossByRsn/html/actionViews/default.html',
            acctLossByRsnContainer: 'templates/widgets/acctLossByRsn/html/actionViews/reportContainer.html',
            acctLossByRsnContent: 'templates/widgets/acctLossByRsn/html/actionViews/reportContent.html',

            //Acct Gain Loss Customers
            acctGainLossCustomersDefault: 'templates/widgets/acctGainLossCustomers/html/actionViews/default.html',
            acctGainLossCustomersContainer: 'templates/widgets/acctGainLossCustomers/html/actionViews/reportContainer.html',
            acctGainLossCustomersContent: 'templates/widgets/acctGainLossCustomers/html/actionViews/reportContent.html',

            //Acct Gains Losses by Tank
            acctGainLossByTankDefault: 'templates/widgets/acctGainLossByTank/html/actionViews/default.html',
            acctGainLossByTankList: 'templates/widgets/acctGainLossByTank/html/actionViews/reportListContent.html',
            acctGainLossByTankContainer: 'templates/widgets/acctGainLossByTank/html/actionViews/acctGainLossByTankActionView.html',
            acctGainLossByTankContent: 'templates/widgets/acctGainLossByTank/html/actionViews/reportContent.html',

            //Acct Gains Losses by Tank
            acctGainLossDefault: 'templates/widgets/acctGainLoss/html/actionViews/default.html',
            acctGainLossList: 'templates/widgets/acctGainLoss/html/actionViews/reportListContent.html',
            acctGainLossContainer: 'templates/widgets/acctGainLoss/html/actionViews/acctGainLossActionView.html',
            acctGainLossContent: 'templates/widgets/acctGainLoss/html/actionViews/reportContent.html',

            //Customer Profile Analysis
            custProfAnalysisContainer: 'templates/widgets/custProfAnalysis/html/actionViews/container.html',

            //Tank Profile Analysis
            tankProfAnalysisContainer: 'templates/widgets/tankProfAnalysis/html/actionViews/container.html',

            //Price Plan Profile Analysis
            pricePlanProfAnalysisContainer: 'templates/widgets/pricePlanProfAnalysis/html/actionViews/container.html',

            //Service Calls
            serviceCallsDefault: 'templates/widgets/serviceCalls/html/actionViews/default.html',
            serviceCallsReportList: 'templates/widgets/serviceCalls/html/actionViews/reportListContent.html',
            serviceCallsReportContainer: 'templates/widgets/serviceCalls/html/actionViews/reportContainer.html',
            serviceCallsReportContent: 'templates/widgets/serviceCalls/html/actionViews/reportContent.html',

            //Excess Calls
            excessCallsDefault: 'templates/widgets/excessCalls/html/actionViews/default.html',
            excessCallsReportList: 'templates/widgets/excessCalls/html/actionViews/reportListContent.html',
            excessCallsReportContainer: 'templates/widgets/excessCalls/html/actionViews/reportContainer.html',
            excessCallsReportContent: 'templates/widgets/excessCalls/html/actionViews/reportContent.html',

            //Service History
            serviceHistoryDefault: 'templates/widgets/serviceHistory/html/actionViews/default.html',
            serviceHistoryReportContainer: 'templates/widgets/serviceHistory/html/actionViews/reportContainer.html',
            serviceHistoryReportContent: 'templates/widgets/serviceHistory/html/actionViews/reportContent.html',

            //Service Contracts Profile
            serviceContractsProfileDefault: 'templates/widgets/serviceContractsProfile/html/actionViews/default.html',
            serviceContractsProfileContainer: 'templates/widgets/serviceContractsProfile/html/actionViews/serviceContractsProfileActionView.html',

            //Service Revenue
            serviceRevenueDefault: 'templates/widgets/serviceRevenue/html/actionViews/default.html',
            serviceRevenueReportList: 'templates/widgets/serviceRevenue/html/actionViews/reportListContent.html',
            serviceRevenueReportContainer: 'templates/widgets/serviceRevenue/html/actionViews/reportContainer.html',
            serviceRevenueReportContent: 'templates/widgets/serviceRevenue/html/actionViews/reportContent.html',

            //Callback Analysis
            callbackAnalysisDefault: 'templates/widgets/callbackAnalysis/html/actionViews/default.html',
            callbackAnalysisReportList: 'templates/widgets/callbackAnalysis/html/actionViews/reportListContent.html',
            callbackAnalysisReportContainer: 'templates/widgets/callbackAnalysis/html/actionViews/reportContainer.html',
            callbackAnalysisReportContent: 'templates/widgets/callbackAnalysis/html/actionViews/reportContent.html',

            //serviceRework
           serviceReworkDefault: 'templates/widgets/serviceRework/html/actionViews/default.html',
           serviceReworkReportList: 'templates/widgets/serviceRework/html/actionViews/reportListContent.html',
           serviceReworkReportContainer: 'templates/widgets/serviceRework/html/actionViews/reportContainer.html',
           serviceReworkReportContent: 'templates/widgets/serviceRework/html/actionViews/reportContent.html',

            snapShotsDefault: 'templates/widgets/snapShots/html/default.html',
            snapShotsContent: 'templates/widgets/snapShots/html/snapShotContent.html',

            //Performance Table Snapshot
            performanceTableTemplate: 'templates/widgets/performance/html/views/performanceTable.html',
            performanceUploadTemplate: 'templates/widgets/performance/html/actionsViews/uploadSnapshot.html',

            //Degree Day Metrics
            degreeDayMetricsDefault: 'templates/widgets/degreeDayMetrics/html/views/degreeDayMetrics.html',
            degreeDayMetricsContainerCtrl: 'templates/widgets/degreeDayMetrics/html/actionsViews/degreeDayMetrics.html',
            degreeDayMetricsLineGraph: 'templates/widgets/degreeDayMetrics/html/views/degreeDayMetricsLineGraph.html',
            degreeDayMetricsBarGraph: 'templates/widgets/degreeDayMetrics/html/views/degreeDayMetricsBarGraph.html',
            
            //Delivery Forecasting
            deliveryForecastingDefault: 'templates/widgets/deliveryForecasting/html/actionViews/default.html',
            deliveryForecastingReportContainer: 'templates/widgets/deliveryForecasting/html/actionViews/reportContainer.html',

            //ADEPT Performance Analysis
            adeptPerformanceAnalysisDefault: 'templates/widgets/adeptPerformanceAnalysis/html/actionViews/default.html',
            adeptPerformanceAnalysisReportContainer: 'templates/widgets/adeptPerformanceAnalysis/html/actionViews/adeptPerformanceAnalysisReportView.html',

            // ADEPT Inquiry
            adeptInquiryDefault: 'templates/adept/inquiry/default.html',
            adeptInquiry: 'templates/adept/inquiry/adeptInquiry.html',
            adeptExecutions: 'templates/adept/inquiry/executions/adeptExecutions.html',
            adeptExecutionDetails: 'templates/adept/inquiry/executionDetails/adeptExecutionDetails.html',

            // ADMIN Users Report
            usersReportDefault: 'templates/admin/reports/users/default.html',
            usersReport: 'templates/admin/reports/users/usersReport.html',
            usersReportDetails: 'templates/admin/reports/users/usersReportDetails/usersReportDetails.html'
        };
    });

    