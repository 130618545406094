angular.module( 'angus.controllers' ).controller( 'resetPasswordCtrl', [
	'$rootScope', '$scope', '$state', '$stateParams', '$http', 'brandingService', '$q', 'constantsService', '_', '$mdToast', 'isConsumer','$location',
	function( $rootScope, $scope, $state, $stateParams, $http, brandingService, $q, constantsService, _, $mdToast, isConsumer,$location) {
        $location.url('/#/ResetPassword');
		if ( !$stateParams.token ) $state.transitionTo( 'signIn' );
        $scope.signInUrl = window.env.envApi + '/#/signIn';
        $scope.alerts = [];
        $scope.branding = brandingService;
        $scope.disableResetPassword = false;
        $scope.resetStatusMessage = "";
        $scope.toggleTerms = function(){
            $scope.showTerms = !$scope.showTerms;
        }

        $scope.minLength = constantsService.passwordRequirements.minLength.value;
		$scope.resetPassword = function() {
            $scope.disableResetPassword = true;
            $scope.resetStatusMessage = "";
		   var deferred = $q.defer();

			var model = {
                username       : $scope.resetPasswordModel.username,
                token          : $stateParams.token,
                password       : $scope.resetPasswordModel.newPassword,
                confirmPassword: $scope.resetPasswordModel.confirmNewPassword
			};

            var prefix = isConsumer ? '/consumer' : '';

			$http.post( prefix + '/resetPassword', model )
				.success( function() {
                    $scope.disableResetPassword = false;
                    $scope.resetStatusMessage = "Your password has been reset.";
                    $location.path('/#/signIn');

					return deferred.resolve();
				} )
				.error( function( err ) {
                    $scope.disableResetPassword = false;
                    $scope.resetStatusMessage = err.msg || 'Invalid username or password.';
					return deferred.reject();
				} );

			return deferred.promise;
		};

	}
] );
