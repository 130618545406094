angular	.module('angus.controllers').controller('dailyLevelsMappingController', [
	'$rootScope', '$scope', 'fluentRest', 'modalService', 'actionViewTemplates', 'moment', '$q', '_', 'promiseMonitor', 'hierarchyService',
	function($rootScope, $scope, fluentRest, modalService, actionViewTemplates, moment, $q, _, PromiseMonitor, hierarchyService) {

		'use strict';
		var existingMapping = $scope.existingMapping;


		function getSubscribers(){
			if($scope.paygoProducts) return $q.when();

			return fluentRest
				.api()
				.subscribers()
				.get()
				.then(function(subscribers){
					$scope.subscribers = subscribers.filter(function(sub){ return sub.id == existingMapping.subscriberId});
				});
		}

		$scope.searchCustomers = function(searchTerm){
			if(!$scope.model.selectedSubscriber.id || !$scope.model.selectedSubscriber.id ||  ($scope.mappingSearchMonitor && $scope.mappingSearchMonitor.isPending())) return $q.when();

			delete $scope.customers;

			var params = {
				pageNumber  : 1,
				pageSize    : 5,
				sortBy      :'name',
				ascending   : true,
				searchTerm  : searchTerm,
				dealerId    : $scope.model.selectedDivision
			};

			var promise = fluentRest
				.api()
				.subscribers($scope.model.selectedSubscriber.id)
				.customerAccounts()
				.get(params)
				.then(function(customers){
					$scope.customers = customers;
					return $scope.customers;
				});

			$scope.mappingSearchMonitor = new PromiseMonitor(promise);

			return promise;
		};

		$scope.clearCustomer = function(){
			delete $scope.customers;
			delete $scope.model.selectedCustomer;
			delete $scope.model.containerId;
		};

		$scope.clearSubscriber = function(){
			$scope.clearCustomer();
			delete $scope.model.selectedSubscriber;
		};

		$scope.selectCustomer = function(customer){
			$scope.model.selectedCustomer = customer;
			$scope.model.containerId = $scope.model.selectedCustomer.containers[0].id;
		};

		$scope.save = function(model){
			// if model.selectedSubscriber has vlaue then user is adding a monitor
			// else moving a monitor so take a user to proper tab


			var result = {
			};

			if($scope.model.selectedSubscriber){
				result.subscriberABOSKey = $scope.model.selectedSubscriber.abosKey;
				result.subscriberName    = $scope.model.selectedSubscriber.name;
				if($scope.model.containerId){
					result.containerId = $scope.model.containerId;
				}
			} 

			this.close(result);
		};

		$scope.setDivisions = function(subscriber) {
			if(!subscriber) {
				return $scope.model.divisions = [];
			}
			var promise = hierarchyService
				.getDealerHierarchy(subscriber.id)
				.then(function(divisions) {
					return $scope.model.divisions = divisions;
				});

			$scope.divisionMonitor.monitor(promise);
			return promise;
		};

		$scope.onSubscriberChange = function() {
			$scope.clearCustomer();

			$scope.model.divisions = [];
			$scope.setDivisions($scope.model.selectedSubscriber);
		};

		function init(){
			$scope.model = {};
			$scope.divisionMonitor = new PromiseMonitor();

			var promise =  getSubscribers()
				.then(function(){
					if(existingMapping){

						$scope.model.selectedSubscriber = _.find($scope.subscribers, function(subscriber){
							return subscriber.id == existingMapping.subscriberId;
						});

						return $scope.model.selectedSubscriber;
					}
				}).then($scope.setDivisions);

			$scope.mappingMonitor = new PromiseMonitor(promise);

		}

		init();
	}])
	.controller('paygoTMSAdminDailyLevelsController', ['$rootScope', '$scope', '$http', '$q', '$filter', 'moment', 'Paging', 'constantsService', 'promiseMonitor', 'csvService','modalService','actionViewTemplates', 'fluentRest', 'alertsService',PaygoTMSAdminDailyLevelsController]);

function PaygoTMSAdminDailyLevelsController($rootScope, $scope, $http, $q, $filter, moment, Paging, constants, PromiseMonitor, csvService, modalService,actionViewTemplates, fluentRest, AlertsService){
	'use strict';

	var today = moment.utc();
	$scope.activeItem.alerts = new AlertsService(); 

	function RangeOption(display, startDate, endDate, displayDates){

		function createUtcDate(momentObj){
			return new Date(moment.utc(momentObj).format('l'))
		}

		var dateDisplay = '';
		if(startDate){
			startDate = createUtcDate(startDate);
			dateDisplay +=  $filter('utcDate')(startDate);
		}

		if(endDate){
			endDate   = createUtcDate(endDate);
			dateDisplay +=  (startDate ? ' - ' : '') + $filter('utcDate')(endDate);
		}
		else if(startDate)
			endDate   = createUtcDate(startDate);


		if(dateDisplay !== '' && displayDates)
			display += ' ('+ dateDisplay + ')';

		this.display = display;

		this.dates = {
			start 	: startDate,
			end 	: endDate
		};

		return this;
	}

	function getParams(){
		return {
			sortBy    : $scope.activeItem.sortBy,
			ascending : $scope.activeItem.ascending,
			startDate : $scope.activeItem.filters.dateRange.dates.start,
			endDate   : $scope.activeItem.filters.dateRange.dates.end,
		};
	}

	function getUrl(){
		return 'api/subscribers/{0}/containers/{1}/dailyLevels'.format($scope.activeItem.monitor.subscriberId, $scope.activeItem.monitor.tankId);
	}

	$scope.getDailyLevelsForContainer = function(pageNumber){

		var params = getParams();
		var url = getUrl();

		 return $scope.activeItem
		 	.dailyLevelsPaging
		 	.getPage(url, pageNumber, params)
		 	.then(function(result) {
		 		$scope.activeItem.dailyLevels = result.items;
				 getMonitorFromCommon();
      		});
	};

	function getMonitorFromCommon() {
		var serialNumber = $scope.activeItem.monitor.serialNumber;
		return $http({
			url: 'api/monitors/{0}/monitor'.format(serialNumber),
			method: 'GET'
		}).then(function (res) {
			var data = res.data;
			if (data.monitorTypeKey) { 
				$scope.activeItem.monitor.monitorTypeKey = data.monitorTypeKey;
			}
			if (data.monitorTypeName) { 
				$scope.activeItem.monitor.monitorTypeName = data.monitorTypeName;
			}
			return data;
		})
	}

	$scope.exportToCsv = function(){
		var params = getParams();
		var url = getUrl();

		return csvService.getCsv(url, params, 'Daily Levels');
	};

	$scope.activeItem.monitor.editMappingDailyLevel = function(){
		var scope = {
			existingMapping : {
				subscriberId  : $scope.activeItem.monitor.subscriberId,//
				accountNumber       : $scope.activeItem.monitor.customer.idDisplay,
				containerId         : $scope.activeItem.monitor.id
			}
		};
		return modalService
			.openTopBarModal(actionViewTemplates.dailyLevelsEditMonitorMapping, scope)
			.then(function(obj){
				if(!obj) return;

				var installedAtDealer = 3; 
				var data = {
					monitors : [{
						serialNumber            : $scope.activeItem.monitor.serialNumber,
						subscriberABOSKey       : obj.subscriberABOSKey,
						containerId             : obj.containerId,
						monitorTypeKey          : $scope.activeItem.monitor.monitorTypeKey,
						monitorInventoryStatusKey: installedAtDealer
					}]
				};

				return fluentRest
					.api()
					.monitors()
					.map()
					.put(data)
					.then(function(success){
						$scope.activeItem.monitor.subscriberABOSKey     = obj.subscriberABOSKey;
						$scope.activeItem.monitor.subscriberName        = obj.subscriberName;
						$scope.activeItem.monitor.containerId           = obj.containerId;
						return getLinkedTank($scope.activeItem.monitor.serialNumber);

					})
					.then(function(){
						if(data.monitors[0].serialNumber && data.monitors[0].subscriberABOSKey && data.monitors[0].containerId){
							var msg = JSON.stringify({name: "mapping_saved", serialNumber: $scope.activeItem.monitor.serialNumber});
							window.postMessage(msg, '*');
							$scope.activeItem.alerts.addSuccess('Monitor Mapping Successfully Updated.');
							$scope.activeItem.tankGeometry = { 
								message:'This task remains UNFINISHED until you confirm the monitor type and geometry details and click the SAVE button.'
							};
						}else if(data.monitors[0].serialNumber && !data.monitors[0].subscriberABOSKey && !data.monitors[0].containerId){
							window.postMessage("mapping_unmapped", '*'); 
							$scope.activeItem.alerts.addSuccess('Monitor Mapping Successfully Updated.');
						}
					});



			});
	};
	function getLinkedTank(serialNumber){
		return fluentRest
			.api()
			.monitors(serialNumber)
			.linkedTank()
			.get()
			.then(function(result){
				$scope.activeItem.monitor = result || {};
				$scope.activeItem.name = result.customer.name;
				$rootScope.$broadcast("savedGeometry", result);
				init();
				$scope.activeItem.activeSection = 'geometry';
			});
	}
	$scope.sort = function(sortBy){
		if($scope.activeItem.sortBy == sortBy)
			$scope.activeItem.ascending = !$scope.activeItem.ascending;
		else
			$scope.activeItem.sortBy = sortBy;

		return $scope.getDailyLevelsForContainer();
	};

	function init(){
		$scope.activeItem.dailyLevelsPaging   = new Paging(20);

		$scope.activeItem.sortBy    = 'readingDate';
		$scope.activeItem.ascending = false;
		$scope.activeItem.rangeOptions = {
      lastThirtyDays 		: new RangeOption('Last Thirty Days', moment.utc(today).subtract(30, 'days'), moment.utc(today), true),
			lastReading 	: new RangeOption('Last Reading', $scope.activeItem.monitor.lastReadingDate ? moment.utc($scope.activeItem.monitor.lastReadingDate) : null, null, true),
			currentWeek 	: new RangeOption('Current Week', moment.utc(today).startOf('week'),moment.utc(today).endOf('week'), true),
			lastWeek 		: new RangeOption('Last Week', moment.utc(today).startOf('week').subtract(7, 'days'), moment.utc(today).endOf('week').subtract(7, 'days'), true),
			currentMonth 	: new RangeOption('Current Month',moment.utc(today).startOf('month'), moment.utc(today).endOf('month'), true),
			lastMonth 		: new RangeOption('Last Month', moment.utc(today).startOf('months').subtract(1, 'months'), moment.utc(today).endOf('months').subtract(1, 'months'), true),
			custom 			: new RangeOption('Custom', moment.utc(today).startOf('month'), moment.utc(today), false)
		};
		$scope.activeItem.filters = {
			dateRange : $scope.activeItem.rangeOptions.lastThirtyDays
		};

		var promises = [
			$scope.getDailyLevelsForContainer(),
		];

		var allPromise = $q.all(promises);

		$scope.activeItem.dailyLevelsMonitor = new PromiseMonitor(allPromise);
	}

	init();
}
