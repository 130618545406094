
angular.module('angus').config(
    ['$httpProvider',
    function ($httpProvider) {
        $httpProvider.interceptors.push(
            ['$q', '$rootScope', '$location', 'authenticationService', '$window',
            function($q, $rootScope, $location, authenticationService, $window) {
                var tokenHeaderName = 'x-access-token';
                return {
                    request: function(config) {
                        config.headers = config.headers || {};

                        if(config.headers.ignoreIntercept){
                            delete config.headers.ignoreIntercept;
                        } else {

                            var token = authenticationService.getToken();
                            if (token){
                                config.headers[tokenHeaderName] = token;
                                if($rootScope.user && !!$rootScope.user.impersonatedBy)
                                    config.headers['iuid'] = $rootScope.user.userId;

                                if(authenticationService.getTimeUntilExpiration() <= 900 )
                                    $rootScope.$emit('resetToken');
                            }
                        }
                        return config;
                    },

                    responseError: function(rejection) {
                       if (rejection.status === 401 && rejection.config.url !== '/signIn')  {
                            authenticationService.clearUserInfo();
                            delete rejection.config.headers[tokenHeaderName];
                            $location.path('/SignIn');
                        }
                        return $q.reject(rejection);
                    }
                };
        }]);
    }]
);
