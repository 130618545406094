angular.module('angus.controllers').controller('subRegAdeptController', [
    '$scope', '$http', '$q', 'adeptService', 'containerService', 'degreeDayService', 'deliveryTypeService', 'divisionService', 'productsService',
    function ($scope, $http, $q, adeptService, containerService, degreeDayService, deliveryTypeService, divisionService, productsService) {

        /* Definitions
            ADEPT Setting: One of the many settings that ADEPT uses: DegreeDayYearCount, DivisionKeys, ProductKeys, etc...

            ADEPT Group: A single instance of values for the full set of ADEPT settings. 
                {id: 1, description: 'Group 1', degreeDayYearCount: 7, ...}

            ADEPT Groups: List of ADEPT Groups
                [{id: 1, description: 'Group 1', ...}, {id: 2, description: 'Group 2', ...}]
        */

        const subscriberId = $scope.subscriber.id;

        const days = {
            sunday: 1,
            monday: 2,
            tuesday: 3,
            wednesday: 4,
            thursday: 5,
            friday: 6,
            saturday: 7
        }

        $scope.degreeDayYearCounts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        $scope.alerts = [];

        /* Functions */
        $scope.clickAddAdeptGroup = clickAddAdeptGroup;
        $scope.clickRefreshContainerTypes = function() {loadContainerTypes(); };
        $scope.submitHandler = submitHandler;
        $scope.cancelHandler = cancelHandler;

        $scope.adeptGroupChanged = adeptGroupChanged;
        $scope.containerTypesChanged = function () { $scope.adeptGroup.persistedSettings.containerTypeIds = $scope.adeptGroup.settings.containerTypeIds; };
        $scope.deliveryTypesChanged = function () { loadContainerTypes(); };
        $scope.divisionsChanged = divisionsChanged;
        $scope.productsChanged = function () { loadContainerTypes(); };
        $scope.sourceSystemChanged = sourceSystemChanged;

        $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
        }

        /* Initialize */
        initialize();


        function initialize(adeptGroupId) {

            /* Different Loading Activities */
            $scope.areAdeptGroupsLoading = true;
            $scope.isAdeptGroupLoading = false;
            $scope.areDivisionsLoading = false;
            $scope.areContainerTypesLoading = false;

            $scope.isDetailSectionEnabled = false;
            $scope.isCreateMode = false;

            $scope.adeptGroup = getDefaultAdeptGroup();


            /* Initialize Data */
            $q.all([
                getAdeptGroups(),
                degreeDayService.getDegreeDayHeaders(subscriberId),
                queryDeliverySchedules(),
                querySourceSystemsAbos(),
                deliveryTypeService.getDeliveryTypes(subscriberId),
                productsService.getBriteProducts(subscriberId)
            ])
                .spread(function (adeptGroups, degreeDayHeaders, deliverySchedules, sourceSystems, deliveryTypes, products) {
                    $scope.degreeDayHeaders = degreeDayHeaders;
                    $scope.sourceSystems = sourceSystems;
                    $scope.deliveryTypes = deliveryTypes;
                    $scope.deliverySchedules = deliverySchedules;
                    $scope.products = products;

                    $scope.divisions = [];
                    $scope.containerTypes = [];

                    if (adeptGroups && adeptGroups.length > 0) {

                        /* Settings List - Order it here so we can display the settings in  */
                        $scope.adeptGroups = _.sortBy(adeptGroups, function (adeptGroup) {
                            return adeptGroup.description.toUpperCase();
                        });

                        if (adeptGroupId && _.some(adeptGroups, { 'id': adeptGroupId })) {
                            $scope.adeptGroup.id = adeptGroupId;
                        }
                        else {
                            $scope.adeptGroup.id = $scope.adeptGroups[0].id;
                        }

                        loadSelectedAdeptGroup();
                    }

                    $scope.areAdeptGroupsLoading = false;
                });
        }

        function getDefaultAdeptGroup() {

            const defaultAdeptGroup = {
                id: null,
                description: '',
                settings: {
                    /* General */
                    isEnabled: true,
                    scheduledDays: {},
                    isBelowMinimumReserveOnly: true,

                    /* Tank Select */
                    divisionIds: [],
                    productIds: [],
                    deliveryTypeIds: [],
                    kFactor: {
                        minimum: 1.8,
                        maximum: 15
                    },
                    containerSize: {
                        minimum: 0,
                        maximum: 0
                    },
                    acquisitionDateExclusion: 0,
                    includeMonitoredTanks: 'Y',
                    includeInternalMonitoredTanks: 'Y',
                    includeCustomersOnHold: false,
                    includeMultiTankDeliveryGroup: true,


                    /* Optimization */
                    isOptimizationEnabled: true,
                    containerTypeIds: [],

                    /* Dynamic Reserve */
                    daysReserved: {
                        notMonitored: 7,
                        monitored: 5
                    },
                    minimumPercentFull: {
                        notMonitored: 20,
                        monitored: 20
                    },
                    degreeDayYearCount: 3,

                    /* Delivery Date Validations */
                    shortDeliveryUsable: 50,
                    minimumDeliveryDays: 0,

                    /* Export */
                    exportCutoffTime: new Date('2022-07-11 23:59'),
                    exportDeliveryDays: 0
                },
                persistedSettings: {
                    divisionIds: [],
                    containerTypeIds: [],
                }
            };

            return defaultAdeptGroup;
        }

        function clickAddAdeptGroup() {
            $scope.isCreateMode = true;
            $scope.isDetailSectionEnabled = true;

            /* Capture the currently selected group, so if the user cancels we can select that group */
            $scope.previouslySelectedAdeptGroupId = $scope.adeptGroup.id;

            /* Add a special place holder to the Adept Settings List drop down */
            $scope.adeptGroups = [{ id: -1, description: '<--- Create New --->' }];

            $scope.adeptGroup = getDefaultAdeptGroup();
            $scope.adeptGroup.id = -1;

            $scope.divisions = [];
            $scope.containerTypes = [];

            $scope.isDetailsLoaded = true;
        }

        function submitHandler(form) {

            /* Call API to save. Get Id Back */
            return saveAdeptGroup()
                .then(function (adeptGroupId) {

                    if (adeptGroupId === -1) /* Error(s) Occurred */ {
                        if (form) {
                            form.$setPristine(); /* Remove Submitted State */
                            form.$setDirty(); /* But leave the form Dirty */
                        }
                        return;
                    }

                    $scope.adeptGroup.id = adeptGroupId

                    /* Set Form Properties */
                    if (form) {
                        form.$setPristine();
                        form.$setUntouched();
                    }

                    /* Call initialize using returned Id */
                    initialize($scope.adeptGroup.id);
                });
        }

        function cancelHandler(form) {
            if (form) {
                form.$setPristine();
                form.$setUntouched();
            }

            if ($scope.isCreateMode) {
                /* If we cancel from Create Mode then reload everything */
                $scope.adeptGroups = [];
                initialize($scope.previouslySelectedAdeptGroupId);
            }
            else {
                loadSelectedAdeptGroup();
            }
            
            $scope.alerts = [];
        }

        function adeptGroupChanged() {
            loadSelectedAdeptGroup($scope.selectedSettingsId);
        }

        function sourceSystemChanged() {
            /* If the Source System Changed then we know we'll be reloading a new set of Divisions. 
                That also means the Selected Divisions will no longer be valid
            */
            $scope.divisions = [];
            $scope.adeptGroup.settings.divisionIds = [];

            loadDivisions();
        }

        function divisionsChanged() {
            $scope.adeptGroup.persistedSettings.divisionIds = $scope.adeptGroup.settings.divisionIds;

            loadContainerTypes();
        }

        function loadSelectedAdeptGroup() {

            $scope.isDetailSectionEnabled = false;
            $scope.isAdeptGroupLoading = true;

            return adeptService.getAdeptGroup(subscriberId, $scope.adeptGroup.id)
                .then(function (adeptGroup) {

                    if (!adeptGroup) {
                        $scope.isAdeptGroupLoading = false;
                        return;
                    }

                    $scope.adeptGroup = {
                        id: adeptGroup.id,
                        description: adeptGroup.description,
                        settings: {
                            /* General */
                            isEnabled: !!adeptGroup.isEnabled,
                            scheduledDays: {
                                sunday: adeptGroup.scheduledDays.includes(days.sunday),
                                monday: adeptGroup.scheduledDays.includes(days.monday),
                                tuesday: adeptGroup.scheduledDays.includes(days.tuesday),
                                wednesday: adeptGroup.scheduledDays.includes(days.wednesday),
                                thursday: adeptGroup.scheduledDays.includes(days.thursday),
                                friday: adeptGroup.scheduledDays.includes(days.friday),
                                saturday: adeptGroup.scheduledDays.includes(days.saturday),
                            },
                            isBelowMinimumReserveOnly: !!adeptGroup.isBelowMinimumReserveOnly,

                            /* Tank Select */
                            sourceSystemId: adeptGroup.sourceSystemKey,
                            divisionIds: adeptGroup.divisionKeys,
                            productIds: adeptGroup.productKeys,
                            deliveryTypeIds: adeptGroup.deliveryTypeKeys,
                            kFactor: {
                                minimum: adeptGroup.kFactorMinimum,
                                maximum: adeptGroup.kFactorMaximum
                            },
                            containerSize: {
                                minimum: adeptGroup.capacityMinimum,
                                maximum: adeptGroup.capacityMaximum
                            },
                            acquisitionDateExclusion: adeptGroup.acquisitionExclusionDays,
                            includeMonitoredTanks: adeptGroup.includeMonitoredTanks,
                            includeInternalMonitoredTanks: adeptGroup.includeInternalMonitoredTanks,
                            includeCustomersOnHold: !!adeptGroup.includeCustomersOnHold,
                            includeMultiTankDeliveryGroup: !!adeptGroup.includeMultiTankDeliveryGroup,

                            /* Optimization */
                            isOptimizationEnabled: !!adeptGroup.isOptimizationEnabled,
                            degreeDayHeaderId: adeptGroup.degreeDayHeaderKey,
                            containerTypeIds: adeptGroup.containerTypeIds,
                            deliveryScheduleKey: adeptGroup.deliveryScheduleKey,

                            /* Dynamic Reserve */
                            daysReserved: {
                                notMonitored: adeptGroup.reserveDays,
                                monitored: adeptGroup.reserveDaysMonitor
                            },
                            minimumPercentFull: {
                                notMonitored: adeptGroup.minimumPercentFull,
                                monitored: adeptGroup.minimumPercentFullMonitor
                            },
                            degreeDayYearCount: adeptGroup.degreeDayYearCount,

                            /* Delivery Date Validations */
                            shortDeliveryUsable: adeptGroup.shortDeliveryUsablePercent,
                            minimumDeliveryDays: adeptGroup.minimumDeliveryDays,

                            /* Export */
                            exportCutoffTime: new Date(adeptGroup.exportCutoffTime.replace('0Z', '0')), /* remove UTC identifier */
                            exportDeliveryDays: adeptGroup.exportDeliveryDays
                        },
                        persistedSettings: {
                            divisionIds: adeptGroup.divisionKeys,
                            containerTypeIds: adeptGroup.containerTypeIds,
                        }
                    }

                    $scope.isDetailSectionEnabled = true;
                    $scope.isAdeptGroupLoading = false;

                    loadDivisions();
                });
        }

        function loadDivisions() {
            $scope.areDivisionsLoading = true;

            $scope.divisions = [];
            $scope.adeptGroup.settings.divisionIds = [];

            /* Since we know Container Types are dependent on Divisions, and will be changing when the Divisions load, just clear them out now. */
            $scope.containerTypes = [];

            const persistedDivisionIds = $scope.adeptGroup.persistedSettings.divisionIds

            return divisionService.getDivisions(subscriberId, false, false)
                .then(function (divisions) {

                    /* Only show divisions associated with the selected Source System */
                    const filteredDivisions = _.filter(divisions, { 'sourceSystemKey': $scope.adeptGroup.settings.sourceSystemId });

                    $scope.divisions = filteredDivisions;
                    $scope.areDivisionsLoading = false;

                    /* Compare Persisted Divisions to Loaded Divisions and set selection based on the Intersection */
                    if (persistedDivisionIds && persistedDivisionIds.length > 0) {
                        const dropDownDivisionIds = _.map($scope.divisions, function (division) {
                            return division.divisionKey
                        });

                        $scope.adeptGroup.settings.divisionIds = _.intersection(dropDownDivisionIds, persistedDivisionIds);
                    }

                    loadContainerTypes();
                });
        }

        function loadContainerTypes() {

            $scope.areContainerTypesLoading = true;

            $scope.containerTypes = [];
            $scope.adeptGroup.settings.containerTypeIds = [];

            const divisionIds = $scope.adeptGroup.settings.divisionIds || [];
            const productIds = $scope.adeptGroup.settings.productIds || [];
            const deliveryTypeIds = $scope.adeptGroup.settings.deliveryTypeIds || [];

            if (divisionIds.length > 0 && productIds.length > 0 && deliveryTypeIds.length > 0) {
                $scope.containerTypeFailure = false;
                containerService.getContainerTypes(subscriberId, divisionIds, undefined, productIds, deliveryTypeIds, undefined)
                    .then(function (containerTypes) {
                        $scope.containerTypes = containerTypes;

                        const persistedContainerTypeIds = $scope.adeptGroup.persistedSettings.containerTypeIds

                        if (persistedContainerTypeIds && persistedContainerTypeIds.length > 0) {
                            const dropDownContainerTypeIds = _.map($scope.containerTypes, function (containerType) {
                                return containerType.id;
                            });

                            $scope.adeptGroup.settings.containerTypeIds = _.intersection(dropDownContainerTypeIds, persistedContainerTypeIds);
                        }

                        $scope.areContainerTypesLoading = false;
                    })
                    .catch(function(error){
                        console.log('Container Type Error', error);
                        $scope.containerTypeFailure = true;
                    });
            }
            else {
                $scope.areContainerTypesLoading = false;
            }
        }


        /* Queries */
        function getAdeptGroups() {
            return adeptService.getAdeptGroups(subscriberId)
                .then(function (adeptGroups) {
                    if (adeptGroups.name == 'RequestError') {
                        return;
                    }

                    return _.map(adeptGroups, function (adeptGroup) {
                        return {
                            id: adeptGroup.id,
                            description: adeptGroup.isEnabled ? adeptGroup.description : adeptGroup.description + ' *',
                            isEnabled: adeptGroup.isEnabled
                        };
                    });
                })
        }

        function querySourceSystemsAbos() {
            return $http.get(('api/subscribers/{0}/sourceSystem/').format(subscriberId))
                .then(function (sourceSystems) {
                    return sourceSystems.data;
                });
        }

        function queryDeliverySchedules(){
            return $http.get(('api/subscribers/{0}/deliverySchedules/').format(subscriberId))
            .then(function(deliverySchedules) {
                return deliverySchedules.data;
            })
        }

        function saveAdeptGroup() {
            const adeptGroupSettings = $scope.adeptGroup.settings;

            let scheduledDays = []
            _.forEach(adeptGroupSettings.scheduledDays, function (value, key) {
                if (value) {
                    scheduledDays.push(days[key]);
                }
            });

            var data = {
                id: $scope.adeptGroup.id < 0 ? null : $scope.adeptGroup.id,
                description: $scope.adeptGroup.description,
                isEnabled: adeptGroupSettings.isEnabled ? 1 : 0,
                scheduledDays: scheduledDays,
                isBelowMinimumReserveOnly: adeptGroupSettings.isBelowMinimumReserveOnly ? 1 : 0,
                sourceSystemKey: adeptGroupSettings.sourceSystemId,
                productKeys: adeptGroupSettings.productIds,
                deliveryTypeKeys: adeptGroupSettings.deliveryTypeIds,
                divisionKeys: adeptGroupSettings.divisionIds,
                kFactorMinimum: adeptGroupSettings.kFactor && adeptGroupSettings.kFactor.minimum,
                kFactorMaximum: adeptGroupSettings.kFactor && adeptGroupSettings.kFactor.maximum,
                capacityMinimum: adeptGroupSettings.containerSize && adeptGroupSettings.containerSize.minimum,
                capacityMaximum: adeptGroupSettings.containerSize && adeptGroupSettings.containerSize.maximum,
                acquisitionExclusionDays: adeptGroupSettings.acquisitionDateExclusion,
                includeMonitoredTanks: adeptGroupSettings.includeMonitoredTanks,
                includeInternalMonitoredTanks: adeptGroupSettings.includeInternalMonitoredTanks,
                includeCustomersOnHold: adeptGroupSettings.includeCustomersOnHold ? 1 : 0,
                includeMultiTankDeliveryGroup: adeptGroupSettings.includeMultiTankDeliveryGroup ? 1 : 0,
                degreeDayYearCount: adeptGroupSettings.degreeDayYearCount,
                reserveDays: adeptGroupSettings.daysReserved && adeptGroupSettings.daysReserved.notMonitored,
                reserveDaysMonitor: adeptGroupSettings.daysReserved && adeptGroupSettings.daysReserved.monitored,
                minimumPercentFull: adeptGroupSettings.minimumPercentFull && adeptGroupSettings.minimumPercentFull.notMonitored,
                minimumPercentFullMonitor: adeptGroupSettings.minimumPercentFull && adeptGroupSettings.minimumPercentFull.monitored,
                isOptimizationEnabled: adeptGroupSettings.isOptimizationEnabled ? 1 : 0,
                degreeDayHeaderKey: adeptGroupSettings.degreeDayHeaderId,
                containerTypeIds: adeptGroupSettings.containerTypeIds,
                deliveryScheduleKey: adeptGroupSettings.deliveryScheduleKey,
                shortDeliveryUsablePercent: adeptGroupSettings.shortDeliveryUsable,
                minimumDeliveryDays: adeptGroupSettings.minimumDeliveryDays,
                exportCutoffTime: adeptGroupSettings.exportCutoffTime && adeptGroupSettings.exportCutoffTime.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit", hour12: false}),
                exportDeliveryDays: adeptGroupSettings.exportDeliveryDays,
            };

            return $http.put(('api/subscribers/{0}/adept/groups/').format(subscriberId), data)
                .then(function (result) {

                    /* Show a Success alert and then hide it after 2 seconds */
                    $scope.alerts.push({type: 'success', msg: 'Successfully Saved'});
                    var alertIndex = $scope.alerts.length - 1;
                    setTimeout(function() {
                        $scope.alerts.splice(alertIndex, 1);
                    }, 2000);

                    var adeptGroupId = result.data.id;
                    return adeptGroupId;
                }, function (response) {
                    if (response.status == 400) {
                        _.forEach(response.data.errors, function (error) {
                            $scope.alerts.push({ type: 'danger', msg: error.msg });
                        })
                    }
                    else {
                        console.log('Server Error on Save: ', response);
                        $scope.alerts.push({ type: 'danger', msg: response.statusText });
                    }

                    return -1
                });
        }
    }
]);







