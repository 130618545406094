angular.module('angus.services')
    .factory("WidgetLibraryResource", [
        '$resource', function($resource) {
            return $resource("api/users/:usersId/widgetGroups/:widgetGroupId",
                { userId: '@userId', widgetGroupId: '@widgetGroupId' },
                {

                }
            );
        }
    ]);