angular.module('angus.controllers').controller('forgotPasswordCtrl', [
    '$rootScope', '$scope', '$http', '$state', '$q', 'brandingService',
    function($rootScope, $scope, $http, $state, $q, brandingService) {

        $scope.branding = brandingService;
        $scope.disableRecoverPassword = false;
        $scope.showTerms = false;
        $scope.forgotStatusMessage = "";
        $scope.toggleTerms = function(){
            $scope.showTerms = !$scope.showTerms;
        }

        $scope.forgotPassword = function() {
            $scope.forgotStatusMessage = "";
            $scope.disableRecoverPassword = true;
            var deferred = $q.defer();

            $http.post('/forgotPassword', $scope.forgotPasswordModel)
                .success(function() {
                    $scope.disableRecoverPassword = false;
                    $scope.forgotStatusMessage = "Reset password link e-mailed.";
                    $state.go('unauthenticated.signIn');
                    var deferred = $q.defer();
                    return deferred.resolve();
                })
                .error(function(err) {
                    $scope.disableRecoverPassword = false;
                    var deferred = $q.defer();
                    $scope.forgotStatusMessage = err && err.msg || 'Invalid username or password.'
                    return deferred.reject();
                });

            return deferred.promise;
        };
        
    }
]);
