'use strict';

angular.module('angus.controllers').controller('subRegListContainerCtrl', [
    '$scope', 'actionViewTemplates', 'constantsService', '$q', '_', 'fluentRest', 'widgetPackageService', 'restrictedAccessService',
    function($scope, actionViewTemplates, constantsService, $q, _, fluentRest, widgetPackageService, restrictedAccessService) {

        const widgetPackages = constantsService.widgetPackages;

        function subscriberLoaded() {
            $scope.subscriberLoaded = true;
            $scope.isLoading = false;

        }

        $scope.isLoading = true;


        $scope.sideNavTemplate = actionViewTemplates.sideNav;
        $scope.hierarchyTemplate = actionViewTemplates.hierarchy;
        $scope.viewAddressesTemplate = actionViewTemplates.subRegViewAddresses;
        $scope.editAddressesTemplate = actionViewTemplates.subRegEditAddresses;
        $scope.viewContactsTemplate = actionViewTemplates.subRegViewContacts;
        $scope.editContactsTemplate = actionViewTemplates.subRegEditContacts;

        function init() {

            var accessPromise = restrictedAccessService.getAccess();

            var subscriberPromise = $q.when($scope.subscriberId ? fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .get() : {})
                .then(function(subscriber) {
                    $scope.subscriber = subscriber;

                    if(subscriber.registrationStatusKey == constantsService.registrationStatus.live)
                            $scope.subscriber.isLive = true;

                    return $scope.subscriber;
                });

            var widgetPackagePromise = widgetPackageService.get(true)
                .then(function(widgetPackages) {
                    $scope.widgetPackages = widgetPackages;
                });

            subscriberPromise
                .then(function(subscriber){
                    var promises = [
                        accessPromise,
                        subscriber,
                        widgetPackagePromise
                    ];

                    var isLive = subscriber.registrationStatusKey == constantsService.registrationStatus.live;

                    return $q
                        .all(promises)
                        .spread(function(access, subscriber, wpp, hasConsumerUsers) {
                            const widgetPackageKeys = subscriber.widgetPackageKeys || [];

                            $scope.adminAccess = access.adminAccess;
                            $scope.adminMode = $scope.adminMode ? _.some($scope.adminAccess, function(val) {
                                return val;
                            }) : false;

                            $scope.registration = {
                                name: 'Registration',
                                icon: 'tasks',
                                templateUrl: actionViewTemplates.subRegRegistration,
                                active: !$scope.adminMode && !subscriber.isAngus
                            };

                            $scope.users = {
                                name: 'Users',
                                icon: 'users',
                                templateUrl: actionViewTemplates.subRegUsers,
                                active: ($scope.adminAccess.users || !$scope.adminMode) && $scope.subscriberId
                            };

                            $scope.consumerUsers = {
                                name: 'Consumer Users',
                                icon: 'users',
                                templateUrl: actionViewTemplates.subRegConsumerUsers,
                                active: ($scope.adminAccess.users || !$scope.adminMode) && subscriber.isPaygo && isLive
                            };

                            $scope.securityGroups = {
                                name: 'Security Groups',
                                icon: 'lock',
                                templateUrl: actionViewTemplates.subRegSecurityGroup,
                                active: ($scope.adminAccess.securityGroups || !$scope.adminMode) && $scope.subscriberId
                            };
                            
                            $scope.defaultDashboards = {
                                name: 'Dashboards',
                                icon: 'dashboard',
                                templateUrl: actionViewTemplates.subRegDefaultDashboards,
                                active: $scope.adminMode && $scope.adminAccess.dashboards &&  $scope.subscriberId 
                                    
                            };

                            $scope.connection = {
                                name: 'Connection Setup',
                                icon: 'cloud-download',
                                templateUrl: actionViewTemplates.subRegConnection,
                                active: !$scope.adminMode && !subscriber.isAngus && $scope.subscriberId
                            };

                            $scope.hierarchy = {
                                name: 'Hierarchy',
                                icon: 'sitemap',
                                templateUrl: actionViewTemplates.subRegHierarchy,
                                active: ($scope.adminAccess.hierarchy || !$scope.adminMode) && isLive
                            };

                            $scope.mapping = {
                                name: 'Mapping',
                                icon: 'puzzle-piece',
                                templateUrl: actionViewTemplates.subRegMapping,
                                active: ($scope.adminAccess.mapping || !$scope.adminMode) && isLive && !subscriber.isAngus
                            };

                            $scope.settingsItem = {
                                name: 'Settings',
                                icon: 'cog',
                                templateUrl: actionViewTemplates.subRegSettings,
                                active: ($scope.adminAccess.settings || !$scope.adminMode) && isLive && !subscriber.isAngus
                            };

                            $scope.budgetsItem = {
                                name: 'Budgets',
                                icon: 'usd',
                                templateUrl: actionViewTemplates.subRegBudgets,
                                active: ($scope.adminAccess.budgets || !$scope.adminMode) && isLive && !subscriber.isAngus
                            };

                            $scope.costsItem = {
                                name: 'Costing',
                                icon: 'money',
                                templateUrl: actionViewTemplates.subRegCosts,
                                active: ($scope.adminAccess.costing || !$scope.adminMode) && isLive && !subscriber.isAngus
                            };

                            $scope.deliveriesItem = {
                                name: 'Deliveries',
                                icon: 'truck',
                                templateUrl: actionViewTemplates.subRegDeliveries,
                                active: ($scope.adminAccess.deliveries || !$scope.adminMode) && isLive
                            };

                            $scope.serviceItem = {
                                name: 'Service',
                                icon: 'wrench',
                                templateUrl: actionViewTemplates.subRegService,
                                active: ($scope.adminAccess.service || !$scope.adminMode) && isLive && 
                                    widgetPackageKeys.includes(widgetPackages.service) && !subscriber.isAngus
                            };

                            $scope.snapshotitem = {
                                name: 'Snapshot',
                                icon: 'camera',
                                templateUrl: actionViewTemplates.subRegPetro,
                                active: ($scope.adminAccess.snapshot || !$scope.adminMode) && isLive && 
                                    widgetPackageKeys.includes(widgetPackages.snapShot) && !subscriber.isAngus
                            };

                            $scope.paygoItem = {
                                name: 'AMS',
                                icon: 'tint',
                                templateUrl: actionViewTemplates.subRegPaygo,
                                active: isLive && !$scope.adminMode && subscriber.isPaygo && !subscriber.isAngus
                            };

                            $scope.adeptItem = {
                                title: 'ADEPT',
                                name: 'ADEPT&trade;',
                                iconUrl: '/img/adept_icon_sm.png',
                                templateUrl: actionViewTemplates.subRegAdept,
                                active: isLive && !$scope.adminMode && subscriber.isAdept && !subscriber.isAngus
                            }

                            $scope.nextGenTest = {
                                title: 'NG Server Test',
                                name: 'NG Server Test',
                                templateUrl: actionViewTemplates.ngServerTest,
                                active: subscriber.isAngus
                            }



                            $scope.items = [
                                $scope.registration,
                                $scope.users,
                                $scope.consumerUsers,
                                $scope.securityGroups,
                                $scope.defaultDashboards,
                                $scope.connection,
                                $scope.hierarchy,
                                $scope.mapping,
                                $scope.settingsItem,
                                $scope.budgetsItem,
                                $scope.costsItem,
                                $scope.deliveriesItem,
                                $scope.serviceItem,
                                $scope.snapshotitem,
                                $scope.paygoItem,
                                $scope.adeptItem,
                                $scope.nextGenTest
                            ];

                            $scope.activeItem = _.find($scope.items, function(item) {
                                return item.active;
                            });
                            $scope.subscriber.saveSuccessful = true;

                            subscriberLoaded();

                        });
            });
        }

        init();

        $scope.setActiveTabs = function(isPaygo, widgetPackageKeys, registrationStatusKey) {
            $scope.subscriber.widgetPackageKeys = widgetPackageKeys;
            $scope.subscriber.registrationStatusKey = registrationStatusKey;
            $scope.paygoItem.active = $scope.subscriber.isPaygo = isPaygo;

            $scope.snapshotitem.active = widgetPackageKeys.includes(widgetPackages.snapShot);

            $scope.serviceItem.active = widgetPackageKeys.includes(widgetPackages.service);
        };
        $scope.switchActiveItem = function(item) {

            $scope.activeItem = item;

        };
    }
]);
