angular.module('angus.controllers').controller('headerController', [
    '$http', '$location', '$rootScope', '$scope', '$window', 'authenticationService', 'dashboardsService', 'modalService', 'sideMenuService', 'windowsService',
    function ($http, $location, $rootScope, $scope, $window, authenticationService, dashboardsService, modalService, sideMenuService, windowsService) {

        $scope.activeDashboard = dashboardsService.getActiveDashboard();

        $scope.activeWindow = windowsService.getActiveWindow();

        if ($scope.activeWindow) {
            $scope.headerTitle = $scope.activeWindow.name;
        }
        else if ($scope.activeDashboard) {
            $scope.headerTitle = $scope.activeDashboard.name;
        }

        $scope.isSideMenuOpen = sideMenuService.isSideMenuOpen();

        $scope.$watch(
            function () {
                return dashboardsService.getActiveDashboard();
            },
            function (newActiveDashboard, oldActiveDashboard) {
                $scope.activeDashboard = newActiveDashboard;

                if (!$scope.activeWindow && newActiveDashboard) {
                    $scope.headerTitle = newActiveDashboard.name;
                }

            }, true
        );

        $scope.$watch(
            function() {
                return sideMenuService.isSideMenuOpen();
            },
            function (newSideMenuStatus, oldSideMenuStatus) {
                $scope.isSideMenuOpen = newSideMenuStatus;
            }
        )

        /* Windows Service events */
        $scope.$on('activeWindowChanged', function(event, newActiveWindow) {
            $scope.activeWindow = newActiveWindow;

            if (newActiveWindow) {
                $scope.headerTitle = "";
            }
            else if ($scope.activeDashboard) {
                $scope.headerTitle = $scope.activeDashboard.name;
            }
        });
        

        $scope.openSideMenu = function () {
            sideMenuService.open();
        }

        
        $scope.editProfileUrl = 'templates/profile/html/editProfileModalContainer.html';

        $scope.closeEditProfile = function() {
            if ($scope.modalInstance) $scope.modalInstance.close();
        };

        $scope.openEditProfile = function() {
            var scope = $scope.$new();
            scope.userProfile = $rootScope.user;
            scope.closeProfileModalCb = $scope.closeEditProfile;

            $scope.modalInstance = modalService.open({
                templateUrl: $scope.editProfileUrl,
                scope: scope
            }, modalService.types.topBar);
        };
        
        $scope.signOut = function() {
            $http({
                method: 'POST',
                url: '/signOut'
            })
            .then(function(){
                authenticationService.clearUserInfo();

                $rootScope.user = null;

                /* Reload the window to start fresh at the Sign In page */
                $window.location.reload(true);
            });
        };

        $scope.stopImpersonating = function() {
            console.log('Stop Impersonating');
            $rootScope = authenticationService.stopImpersonating();
            $window.location.reload(true);
        };


    }
]);
